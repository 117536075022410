import * as React from 'react'
import { Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import AboutUs from './pages/AboutUs';
import CrossFitPage from './pages/CrossFit';
import Header from './component/Header/Header';
import Footer from './component/Footer/Footer';
import SweatPage from './pages/Sweat';
import JustGym from './pages/JustGym';
import PersonalTraining from './pages/PersonalTraining';
import PostNatal from './pages/PostNatal';
import WorkforPerpetua from './pages/WorkforPerpetua';
import ContactUs from './pages/contact';
import OnlinePrograming from './pages/OnlinePrograming';
import Nutrition from './pages/Nutrition';
import Rumble from './pages/Rumble';
import Location from './pages/Location';
import RidePage from './pages/Ride';
import BlogListing from './pages/BlogListing';
import TimeTable from './pages/TimeTable';
import Pricing from './pages/Pricing';
import Studios from './pages/Studios';
import Mojo from './pages/Mojo';
import Application from './pages/Application';
import Highrox from './pages/Highrox';
import TermsConditions from './pages/TermsConditions';
import Corporate from './pages/Corporate';
import Membership from './pages/Membership';
import BlogDetails from './pages/BlogDetails';
import PrivacyPolicy from './pages/PrivacyPolicy';
import TeamListing from './pages/TeamListing';
import Podcast from './pages/Podcast';
import Page404 from './pages/Page404';
import Shop from './pages/Shop';
import Scrolltotop from './ScrollToTop';
import TeamDetails from './pages/TeamDetails';
import FrontOfHouse from './pages/FrontOfHouse';
import Facilitics from './pages/Facilitics';
import CrossfitCoach from './pages/CrossfitCoach';
// import LadiesGroup from './pages/LadiesGroup';
import PrivateHire from './pages/PrivateHire';
import ResidentialServices from './pages/ResidentialServices';
import WOW from 'wowjs';
import InBody from './pages/InBody';
import SignUp from './pages/SignUp';
import Iconic from './pages/Iconic';
import Cancellation from './pages/Cancellation';
import WhatsappIcon from './component/WhatsappIcon/WhatsappIcon';
import TemporarilyLanding from './pages/TemporarilyLanding';
import ThankYou from './pages/ThankYou';
import TreadandshredLandingpage from './pages/TreadandshredLandingpage';
import RideFirsttimersLandingpage from './pages/RideFirsttimers';
import RumbleLandingPage from './pages/RumbleLandingPage';
import WindmillLocation from './pages/WindmillLocation';
import LennoxstreetLocation from './pages/LennoxstreetLocation';
import LandingPage from './pages/LandingPage';
import UnbrokinLandingpage from './LandingPage/UnbrokinLandingpage';
import Ride45Page from './pages/Ride45';
import JoinnowLandingpage from './pages/JoinnowLandingpage';
import CookieConsent from "react-cookie-consent";
import CookeiConsentBanner from './component/CookeiConsent/CookeiConsentBanner';
import ResolutionLandingpage from './LandingPage/ResolutionPacks';
import HealthBar from './pages/HealthBar';
import RideToRhythmride from './pages/RideToRhythmride';
import Career from './pages/Career';
import ValentinesTakeover from './LandingPage/ValentinesTakeover';
import UnbrokenPoweredByWhoop from './LandingPage/UnbrokenPoweredByWhoop ';
import FirstTimers from './pages/FirstTimers';
import Rumble12Rounds from './pages/Rumble12Rounds';
import Golf from './pages/Golf';
import OurStudios from './pages/OurStudios';
import StudioClassDescriptions from './pages/StudioClassDescriptions';
import NewStudios from './pages/NewStudios';
import EasterBunnyBundles from './LandingPage/EasterBunnyBundles';
import SummerOpeningParties from './LandingPage/SummerOpeningParties';
import TreadAndShredInstructor from './pages/TreadAndShredInstructor';
// import FrontOfHouseLead from './pages/FrontOfHouseLead';
import Testimonials from './pages/Testimonials';
import Beginners from './pages/Beginners';
import DropIn from './pages/DropIn';
import Story from './pages/Story';
import HyroxFirstTimers from './pages/HyroxFirstTimers';
import HyroxSimulation from './LandingPage/HyroxSimulation';
import SummerClosingParty from './LandingPage/SummerClosingParty';
import BratSummer from './LandingPage/BratSummer';
import Ride45FirstTimers from './pages/Ride45FirstTimers';
import MarketingAndEventsManager from './pages/MarketingAndEventsManager';
import UnbrokenPoweredbyRAD from './LandingPage/UnbrokenPoweredbyRAD';
import Faqs from './pages/Faqs';
import RhythmRideLiquidDeath from './pages/RhythmRideLiquidDeath';
import CorporateFitnessManagment from './pages/CorporateFitnessManagment';
import ChristmasGiftVouchers from './pages/ChristmasGiftVouchers';
import BoxingAcademy from './pages/BoxingAcademy';
import UnbrokenPoweredByAlo from './LandingPage/UnbrokenPoweredByAlo';
import StudioMembership from './LandingPage/StudioMembership';


class App extends React.Component {
  // Defining WOW 
  componentDidMount() {
    new WOW.WOW({
      live: true,
      offset: 0,
      mobile: true,
    }).init();
  }
  render() {

    return (
      <>
        <Header link="https://apps.apple.com/ie/app/perpetua-fitness/id1444090131" targetBlank={true} />
        <Scrolltotop />
        <Routes>
          <Route path="/" element={<Home />} />
          {/* <Route path="/about" element={<AboutUs />} /> */}
          <Route path="/tread-and-shred" element={<SweatPage />} />
          <Route path="/crossfit-dublin" element={<CrossFitPage />} />
          <Route path="/luxury-gym-dublin-city-centre" element={<JustGym />} />
          <Route path="/personal-training-dublin" element={<PersonalTraining />} />
          <Route path="/pregnancy-exercise-dublin" element={<PostNatal />} />
          <Route path="/work-for-perpetua" element={<WorkforPerpetua />} />
          <Route path="/online-gym-programming" element={<OnlinePrograming />} />
          <Route path="/nutrition-coaching-dublin" element={<Nutrition />} />
          <Route path="/location" element={<Location />} />
          <Route path="/boxing-class-dublin" element={<Rumble />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/spin-class-dublin" element={<RidePage />} />
          <Route path="/spin-studio-dublin" element={<Ride45Page />} />
          <Route path="/blog" element={<BlogListing />} />
          <Route path='/blog/category/:category_id' element={<BlogListing />} />
          <Route path='/blog/by-coach/:coach_slug' element={<BlogListing />} />
          <Route path='/blog/search' element={<BlogListing />} />
          <Route path="/timetable" element={<TimeTable />} />
          <Route path="/pricing" element={<Pricing />} />
          <Route path="/pricing/:tab" element={<Pricing />} />
          {/* <Route path="/studios" element={<Studios />} /> */}
          <Route path="/fitness-classes-dublin" element={<NewStudios />} />
          <Route path="/mojo" element={<Mojo />} />
          <Route path="/application" element={<Application />} />
          <Route path="/hyrox-dublin" element={<Highrox />} />
          <Route path='/terms-condition' element={<TermsConditions />} />
          <Route path="/corporate-wellness" element={<Corporate />} />
          <Route path='/membership' element={<Membership />} />
          <Route path='/privacy-policy' element={<PrivacyPolicy />} />
          <Route path='/blog/:slug' element={<BlogDetails />} />
          <Route path='/podcast' element={<Podcast />} />
          <Route path='*' element={<Page404 />} />
          <Route path='/shop' element={<Shop />} />
          <Route path='/team' element={<TeamListing />} />
          <Route path='/team/:slug' element={<TeamDetails />} />
          <Route path='/font-of-house' element={<FrontOfHouse />} />
          <Route path='/facilitics' element={<Facilitics />} />
          <Route path='/crossfit-coach' element={<CrossfitCoach />} />
          {/* <Route path='/ladies-gym-dublin' element={<LadiesGroup />} /> */}
          <Route path='/private-gym-hire-dublin' element={<PrivateHire />} />
          <Route path='/residential-services' element={<ResidentialServices />} />
          <Route path='/inbody' element={<InBody />} />
          <Route path='/sign-up' element={<SignUp />} />
          <Route path='/iconic' element={<Iconic />} />
          <Route path='/cancellation' element={<Cancellation />} />
          <Route path='/summer-party' element={<TemporarilyLanding />} />
          <Route path='/thank-you' element={<ThankYou />} />
          <Route path='/tread-and-shred-first-timers' element={<TreadandshredLandingpage />} />
          <Route path='/ride-first-timers' element={<RideFirsttimersLandingpage />} />
          <Route path='/rumble-first-timers' element={<RumbleLandingPage />} />
          <Route path='/windmill-lane' element={<WindmillLocation />} />
          <Route path='/lennox-street' element={<LennoxstreetLocation />} />
          <Route path='/join-the-guest-list' element={<LandingPage />} />
          <Route path='/unbroken-powered-by-lululemon' element={<UnbrokinLandingpage />} />
          <Route path='/join-today' element={<JoinnowLandingpage />} />
          {/* <Route path='/resolution-packs' element={<ResolutionLandingpage />} /> */}
          <Route path="/ride" element={<RideToRhythmride />} />
          <Route path='/what-we-offer/health-bar' element={<HealthBar />} />
          <Route path='/careers' element={<Career />} />
          <Route path='/valentines-takeover' element={<ValentinesTakeover />} />
          <Route path='/unbroken-powered-by-whoop' element={<UnbrokenPoweredByWhoop />} />
          <Route path='/first-timers' element={<FirstTimers />} />
          {/* <Route path='/rumble-12-rounds' element={<Rumble12Rounds />} /> */}
          {/* <Route path='/golf-fitness-training-dublin' element={<Golf />} /> */}
          <Route path='/our-studios' element={<OurStudios />} />
          <Route path='/studio-class-descriptions' element={<StudioClassDescriptions />} />
          <Route path='/easter-bunny-bundles' element={<EasterBunnyBundles />} />
          <Route path='/summer-opening-parties' element={<SummerOpeningParties />} />
          <Route path='/treadandshred-instructor' element={<TreadAndShredInstructor />} />
          <Route path='/hyrox-simulation-dublin' element={<HyroxSimulation />} />
          {/* <Route path='/front-of-house-lead' element={<FrontOfHouseLead />} /> */}
          <Route path='/perpetua-testimonials' element={<Testimonials />} />
          <Route path='/crossfit-dublin/beginners' element={<Beginners />} />
          <Route path='/crossfit-dublin/drop-in' element={<DropIn />} />
          <Route path='/story' element={<Story />} />
          <Route path='/hyrox-first-timers' element={<HyroxFirstTimers />} />
          <Route path='/summer-closing-party' element={<SummerClosingParty />} />
          <Route path='/brat-themed-spin-class-dublin' element={<BratSummer />} />
          <Route path='/ride45-first-timers' element={<Ride45FirstTimers />} />
          <Route path='/marketing-manager' element={<MarketingAndEventsManager />} />
          <Route path='/unbroken-powered-by-rad' element={<UnbrokenPoweredbyRAD />} />
          <Route path="/faqs" element={<Faqs />} />
          <Route path="/rhythm-ride-liquid-death" element={<RhythmRideLiquidDeath />} />
          <Route path="/corporate-fitness-managment" element={<CorporateFitnessManagment />} />
          <Route path="/christmas-gift-cards" element={<ChristmasGiftVouchers />} />
          <Route path="/boxing-academy" element={<BoxingAcademy />} />
          <Route path='/unbroken-powered-by-alo' element={<UnbrokenPoweredByAlo />} />
          <Route path='/studio-membership' element={<StudioMembership />} />
        </Routes>
        <WhatsappIcon Icon='/images/whatsapp.svg' />
        <Footer />
        <CookeiConsentBanner />
      </>
    );
  }
}

export default App;


