import React from 'react';
import { Container, Link, Text } from '@chakra-ui/react';
import * as Style from './LandingStyle.style';
import MetaTitle from '../pages/MetaTitle';

const UnbrokenPoweredByAlo = () => {
    return (
        <>
            <MetaTitle title='Unbroken Powered by Alo  | Perpetua Fitness' />

            <Style.PageBanner backgroundImage={{ sm: 'url(./images/alo_landing_mobile.webp)', md: 'url(./images/UnbrokenPoweredByAlo.webp)' }} href="https://clients.mindbodyonline.com/classic/ws?studioid=535079&stype=43&prodid=108082" target='_blank'></Style.PageBanner>

            <Style.CenterContent>
                <Container maxW={{ md: 'container.lg', xl: 'container.xl' }}>
                    <Style.CenterContentWrapper marginBottom='20px'>
                        <Style.CenterContentHeader>
                            <Style.h2 variant='medium' title='Get ready to go Unbroken Powered by Alo!' />
                            <Style.h2 variant='medium' title='MARCH 1-8' margin='0 auto' />
                            <Style.p title='8 Studio Classes. 8 Days. Unmissable prizes from Alo.' fontSize={{ lg: '16px', xl: '18px' }} />
                        </Style.CenterContentHeader>
                        <Style.p title='Complete the challenge and take home your very own Alo prize!'></Style.p>
                        <Style.p title='You don’t want to miss this one.'></Style.p>
                    </Style.CenterContentWrapper>
                    <Style.pageButton href='https://clients.mindbodyonline.com/classic/ws?studioid=535079&stype=43&prodid=108082' target='_blank'>I’m ready</Style.pageButton>
                </Container>
            </Style.CenterContent>

            <Style.midContent>
                <Container maxW={{ md: 'container.lg', xl: 'container.xl' }}>
                    <Style.midcontentWrapper>
                        <Style.imageBox>
                            <Style.image alt='image' src="./images/UB-left-image.jpg" />
                        </Style.imageBox>
                        <Style.contentBox>
                            <Style.h2 variant='small' title='Want to know what it takes to complete the challenge and earn your reward?' marginBottom='20px' />
                            
                            <Style.pointList>
                                <Style.pointlistItem>Grab your Unbroken Challenge Pack <Link href="https://clients.mindbodyonline.com/classic/ws?studioid=535079&stype=43&prodid=108082" textDecoration='underline' target='_blank'>here</Link> and use it to book into class every day from March 1-8.</Style.pointlistItem>
                                <Style.pointlistItem>Collect your Challenge Starter Pack, with goodies from Alo and SQUID on Day 1, HIIT the studio every day for 8 days and make sure that you tap your SQUID at reception when you check in for each class (the important part!). </Style.pointlistItem>
                                
                                <Style.pointlistItem>On Day 8, if you’ve completed the challenge, you’ll receive an even bigger Alo prize. Trust us that you are going to want to finish this challenge!</Style.pointlistItem>

                                <Style.pointlistItem>For everyone that takes part, we’ll be hosting our Unbroken studio party in Windmill Lane from 9am on Saturday, March 8th so you can celebrate all your hard work over the challenge and meet your studio buddies. </Style.pointlistItem>
                               
                            </Style.pointList>
                            <Style.pageButton href='https://clients.mindbodyonline.com/classic/ws?studioid=535079&stype=43&prodid=108082' target='_blank' marginTop='25px'>GO UNBROKEN</Style.pageButton>
                        </Style.contentBox>
                    </Style.midcontentWrapper>
                </Container>
            </Style.midContent>

            <Style.faqSection>
                <Container maxW={{ md: 'container.lg', xl: 'container.xl' }}>
                    <Style.h2 variant='extralarge' title='FAQS' textAlign='center' />
                    <Style.p title='Check out our FAQ below for all the juicey details:' fontSize='18px' paddingBottom='30px' textAlign='center' />

                    <Style.faqBox allowToggle>
                        <Style.faqItem >
                            <Style.itemButton>
                                <Style.h2 variant='extrasmall' title="What is Unbroken?" />
                                <Style.itemIcon />
                            </Style.itemButton>
                            <Style.itemArea>
                                <Text fontFamily='Poppins' fontSize='16px'>A challenge for your mind & body (with a spicy reward!). A challenge that originated back in 2020 which encourages you to smash 8 studio classes in 8 days. Your Unbroken Challenge pack  includes Tread & Shred, Ride, Rumble and HYROX! The perfect way to add some motivation into your fitness routine. Get sweaty, challenge yourself in different classes and get to know new coaches. 
                                </Text>

                                <Text fontFamily='Poppins' fontSize='18px' fontWeight='bold' marginTop='15px'>How it works:  </Text>
                                <Text fontFamily='Poppins' fontSize='16px' marginBottom='10px'>Grab your UNBROKEN Class pack - 8 credits for €98 <Link href="https://clients.mindbodyonline.com/classic/ws?studioid=535079&stype=43&prodid=108082" textDecoration='underline' target='_blank'> here </Link> and use it to book into one class each day from March 1-8. This is the perfect chance to try out different classes and coaches. Commit to yourself and smash it! </Text>

                                <Text fontFamily='Poppins' fontSize='16px' fontWeight='500' marginBottom='10px'>See how far you can get in the 8-day challenge! Participants will receive a ticket to our exclusive event on Day 8 in our Windmill Lane studio along with an unmissable prize from Alo!</Text>

                                <Text fontFamily='Poppins' fontSize='16px' fontWeight='500' marginBottom='10px'>To participate in the challenge, you must have an Unbroken class pack</Text>
                            </Style.itemArea>
                        </Style.faqItem>

                        <Style.faqItem >
                            <Style.itemButton>
                                <Style.h2 variant='extrasmall' title="Can I use my regular credits to take part?" />
                                <Style.itemIcon />
                            </Style.itemButton>
                            <Style.itemArea>
                                <Text fontFamily='Poppins' fontSize='16px' marginBottom='10px'>Only classes attended under your 8 unbroken credits will count towards the challenge. All other studio credit packs and studio memberships will be put on hold while you complete the challenge. </Text>
                                
                                <Text fontFamily='Poppins' fontSize='16px' marginBottom='10px'>Save your regular credits for after!</Text>
                            </Style.itemArea>
                        </Style.faqItem>

                        <Style.faqItem >
                            <Style.itemButton>
                                <Style.h2 variant='extrasmall' title="What do I get for completing all 8 Days?" />
                                <Style.itemIcon />
                            </Style.itemButton>
                            <Style.itemArea>
                                <Text fontFamily='Poppins' fontSize='16px' marginBottom='10px'>(Apart from bragging rights)  </Text>
                                <Text fontFamily='Poppins' fontSize='16px' marginBottom='10px'>Those who complete all 8 days, will receive a card that you can bring to the Alo Grafton Street store to redeem a finisher prize! You'll also get something from us just for taking part, so don't worry if you don't finish!</Text>
                                <Text fontFamily='Poppins' fontSize='16px' marginBottom='10px' fontWeight='500'>You’ll also receive a ticket to our exclusive event on Day 8 in our Windmill Lane location
                                where you’ll be able to recover and celebrate the end of the challenge!</Text>
                                <Text fontFamily='Poppins' fontSize='16px' marginBottom='10px' fontWeight='500'>Participants who take part will still receive a ticket to our exclusive event on Day 8 in our Windmill Lane location!</Text>
                            </Style.itemArea>
                        </Style.faqItem>

                        <Style.faqItem >
                            <Style.itemButton>
                                <Style.h2 variant='extrasmall' title="What’s the ‘Unbroken’ party?" />
                                <Style.itemIcon />
                            </Style.itemButton>
                            <Style.itemArea>
                                <Text fontFamily='Poppins' fontSize='16px' marginBottom='10px'>We’ll be hosting an exclusive Unbroken party for ALL of our studio guests in our Windmill Lane location. Whether you completed the challenge, took part or supported your studio buddy along the way, you’re invited. Keep an eye on your inbox for your e-invite! </Text>
                            </Style.itemArea>
                        </Style.faqItem>
                    </Style.faqBox>

                </Container>
            </Style.faqSection>

            <Style.CenterContent padding={{sm:"20px 0 50px !important", md:"30px 0 60px"}}>
                <Container maxW={{ md: 'container.lg', xl: 'container.xl' }}>
                    <Style.CenterContentWrapper>
                        <Style.CenterContentHeader>
                            <Style.h2 variant='medium' title='Terms & Conditions ' />
                            <Text fontFamily='Poppins' fontSize='18px' marginBottom='10px' marginTop='20px' fontWeight='700'>Do you accept the challenge?</Text>
                            <Text fontFamily='Poppins' fontSize='16px' marginBottom='10px' textAlign="left"> UNBROKEN Class Packs are valid for use from 1-8 March. Credits cannot be extended or paused. You must use the UNBROKEN Class Credits to participate in the challenge and tap your SQUID every single day that you complete. Any other studio credit packs and studio memberships will be paused while you complete the challenge. </Text>
                            <Text fontFamily='Poppins' fontSize='16px' marginBottom='10px' textAlign="left">Challenge pack valid for use in Ride, Tread & Shred, Rumble & HYROX classes in both Windmill Lane and Lennox
                            Street locations.</Text>
                            <Text fontFamily='Poppins' fontSize='16px' marginBottom='10px' textAlign="left">To avail of your Alo finisher prize, you must have completed all 8 days of the challenge AND tapped your SQUID
                            every day so that we can keep track. </Text>
                           

                        </Style.CenterContentHeader>
                    </Style.CenterContentWrapper>
                </Container>
            </Style.CenterContent>
        </>
    )
}

export default UnbrokenPoweredByAlo;
