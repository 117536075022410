import React, {useEffect} from 'react';
// import InnerBanner from '../component/InnerBanner/InnerBanner';
import Heading from '../component/Heading/Heading';
import Text from '../component/SubHeading/SubHeading';
import {Box,Stack,Container,Flex,SimpleGrid,VStack,Link,Image,GridItem ,Accordion, AccordionButton, AccordionItem, AccordionIcon, AccordionPanel } from '@chakra-ui/react';
import WhatsincludedCard from '../component/WhatsIncluded Card/WhatsincludedCard';
import Testimonial from '../component/Testimonial/Testimonial';
import PriceBox from '../component/Price Component/PriceBox';
import MarqueLoop from '../component/Marque Loop/MarqueLoop';
import ContactForm from '../component/Form/ContactForm';
import LinkButton from '../component/Button/LinkButton';
// import WhyMembershipCard from '../component/WhyMemberShip/WhyMembershipCard';
import MetaTitle from './MetaTitle';
import WOW from 'wowjs';
// import VideoSection from '../component/VideoSection/VideoSection';
import VideoBanner from '../component/VideoBanner/VideoBanner';
import * as Style from './Crossfit.style';

import BlogSection from '../component/Blog/BlogSection';
import Button from '../component/Button/CustomeButton';
import LazyLoad from "react-lazy-load";



const whyChoosePostNataData =[
  {
    id: '1',
    title: 'CERTIFIED & EXPERIENCED COACHES',
    description:'Whether you are pregnant or have already given birth, Loz will help you on your journey. She is a CPPC Certified Pre & Post Natal Coach and has an option to suit all pregnancies and abilities.'
  },
  {
    id: '2',
    title: 'COMFORTABLE & SAFE ENVIRONMENT',
    description:'Perpetua has the best facilities available for you to come in and get your session in. You will feel safe with the guidance from our coaches and enjoy the friendly environment.'
  },
  {
    id: '3',
    title: 'VARIETY OF TRAINING OPTIONS',
    description:'We have systems in place to make sure you are receiving the best service available. Each session will delivered online, you can track results, see your progress, communicate with your coach and receive daily accountability.'
  }
]


const WhatYouGetData  = [
  {
    id: '1',
    title: 'EXPERIENCED COACHING',
    description: 'Whether you are pregnant or have already given birth, Loz will help you on your journey. She is a CPPC Certified Pre & Post Natal Coach and has an option to suit all pregnancies and abilities.',
    image:  '/images/weight-icon.svg'  
  },
  {
    id: '2',
    title: 'PERSONAL PROGRAM',
    description: 'You will receive your weekly program via an app so you can understand exactly what you are working towards, log your numbers and stats and see the progress you are making with the team.',
    image: '/images/Specialclasses.svg'
  },
  {
    id: '3',
    title: 'COMFORTABLE & SAFE ENVIRONMENT',
    description: 'Perpetua has the best facilities available for you to come in and get your session in. You will feel safe with the guidance from our coaches and enjoy the friendly environment.',
    image: '/images/justgym-access.svg' 
  },

]


const metaTitle="Pregnancy Exercise Classes Dublin | Perpetua Fitness"
const metaDesc="Pregnancy exercise classes in Dublin with Perpetua Fitness. Pre natal & post natal training. Train safely during and after pregnancy."


function PostNatal(props) {

  useEffect(() => {
    new WOW.WOW({
      live: true,
      offset: 0,
      mobile: true,
    }).init();
  }, [])

  return (
    <>
      <MetaTitle title={metaTitle} content={metaDesc}/>

      <VideoBanner 
        videobanner='../video/Website-banner.mp4'
        subtitle='PREGNANCY EXERCISE CLASSES DUBLIN'
        title='PRE & POST NATAL TRAINING'
        subtitleRight={<Box display='inline-block'><Image src='../images/map-white.svg' display='inline-block' top='12px' position='relative'></Image>Available at our Windmill Lane Facility</Box>}
        isButton={true} 
        issubtextpara={false} 
        buttontitle="Enquire now"
        margin="0px 0 0"
        Link="/contact-us"
        videoModal='https://www.youtube.com/embed/-3cC0zZ6yt0' 
        targetBlank={false}
        modalButtontitle="View The Experience"
        issubtitleRight={true} 
      />
      
      <Flex  backgroundColor='black' padding={{sm:'15px 0px 0px' ,md:'30px 0px 0px',xl:'55px 0px 0px'}}>
        <Container maxW='container.xl'>
          <MarqueLoop></MarqueLoop>
        </Container>
      </Flex>
      <Flex  backgroundColor='black' padding={{sm:'50px 15px',md:'80px 20px',xl:'100px 0px'}}>
        <Container maxW='container.xl'>
          <Stack direction={{base:'column',md:'row'} } spacing='30px' textAlign={{base:'left', lg:'left'}} alignItems='center'>
              <Box w={{ base:'100%' ,lg:'50%'}} padding={{md:'0px 20px',lg:'0px 0px'}}>
                <PriceBox 
                  color='white'
                  manheadind='Pre Natal-Group 150€/6 Weeks '
                  time='/6 Weeks '
                  description={<Box>We have a Pre-Natal Group that runs for a 6-week block that involves 1 session per week.</Box>}
                  buttonText='Enquire Now'
                  link='/contact-us'
                  buttonPading='0px'
                  issubHeading={false}
                  width={{base:'100%', sm:'70%'}}
                  isHeading={false}
                  isButton={false}
                >
                </PriceBox>
              </Box>
              <Box w={{ base: '100%', lg: '50%' }} padding={{ md: '0px 20px', lg: '0px 0px' }}>
                <PriceBox 
                  color='white'
                  currency='€'
                  priceValue='Pre Natal-Group'
                  time='150e/6 Weeks'
                  description={<Box>We have a Post-Natal Group that runs for a 6-week block that includes 2 sessions per week.</Box>}
                  buttonText='Enquire Now'
                  link='/contact-us'
                  buttonPading='0px'
                  issubHeading={false}
                  width={{base:'100%', sm:'70%'}}
                  isHeading={false}
                  manheadind='Post Natal-Group 300€/6 weeks'
                  isButton={false}
                >
                </PriceBox>
              </Box>
          </Stack>
          <Box w='100%' textAlign={{sm:'left',md:'center',lg:'center'}} marginTop={{lg:'50px',md:'50px',sm:'25px'}}>
            <Link href='/contact-us'>
              <Button margin='30px 0px 0px' className="wow fadeInUp" title='Enquire Now' />
            </Link>
          </Box>
          <Box textAlign={{sm:'left',md:'center',lg:'center'}} marginTop='30px'>
            <Text title={<Box>Email Loz on <Link href='mailto:lorraine@perpetua.ie' textDecoration='underline' color='#d12c41'>lorraine@perpetua.ie</Link> find out more information on our available services.</Box>} color='#fff'></Text>
          </Box>
        </Container>
      </Flex>

      <Box padding={{ sm: '50px 0px', md: '100px 0px' }} backgroundColor='LightGrey'>
        <Container maxW='container.xl'>
          <Heading
            variant="extralarge"
            title='WHY CHOOSE PERPETUA'
            margin={{ sm: '0px auto 50px', md: '0px auto 86px' }}
            width={{ base: '90%', md: '60%' }}
            textAlign='center'
            lineHeight='1'
            color='black'
          />
          <SimpleGrid
            columns={{ sm: '1', md: '3' }}
            spacing={5}
            textAlign='center'
            justifyContent='center'
          >
            <GridItem>
              <Heading title='01' color='Red' textAlign='center' />
              <Heading
                title='EXPERIENCED COACHES'
                color='black'
                variant='small'
                padding='15px 20px'
              />
              <Text
                title='Whether you are pregnant or have already given birth, Loz will help you on your journey. She is a CPPC Certified Pre & Post Natal Coach and has an option to suit all pregnancies and abilities.
                '
                color='black'
                padding='15px 20px'

              />
            </GridItem>
            <GridItem>
              <Heading title='02' color='Red' textAlign='center' />
              <Heading
                title='COMFORTABLE & SAFE ENVIRONMENT'
                color='black'
                variant='small'
                padding='15px 20px'
              />
              <Text
                title='Perpetua has the best facilities available for you to come in and get your session in. You will feel safe with the guidance from our coaches and enjoy the friendly environment.'
                padding='15px 20px'
                color='black'
              />
            </GridItem>
            <GridItem>
              <Heading title='03' color='Red' textAlign='center' />
              <Heading
                title='Friendly Community'
                color='black'
                variant='small'
                padding='15px 20px'
              />
              <Text
                title='We understand that coming into a gym with your baby can be hard so we make sure all classes are run at our quieter times so you feel comfortable with the surroundings and maybe your little one can have a nap.
                '
                color='black'
                padding='15px 20px'
              />
            </GridItem>
          </SimpleGrid>
        </Container>
      </Box>

      <Flex flexDirection='column' padding={{sm:'50px 0px', md:'90px 0px' }} position="relative" >
        <Container maxW='container.xl'>
          <Testimonial headingWidth={{sm:'97%',md:'90%'}} iconUri='/images/comment-quote-outline.svg' isname={true} 
          title='“Having a baby changed everything for me! I’m delighted with the information I received 
          during my pregnancy and Loz helped me so much on this journey.”' name='- PERPETUA CLIENT'/>

        </Container>
      </Flex>

      <Box backgroundColor='black' padding={{ sm: '60px 0px', md: '80px 0px' }} borderTop='1px solid #232323'>
        <Container maxW='container.xl'>
          
            <Box>
              <Heading 
              variant="large" 
              title='PRE/POST NATAL' 
              lineHeight='1'
              color='white'
              textAlign='center'
              />
              <Heading 
              variant="large" 
              title='TRAINING AT PERPETUA' 
              lineHeight='1'
              color='white'
              textAlign='center'
              />
            </Box>
            <Style.VideoWrapperNatal>
              <Style.VideoWrapper width='100% !important'>
                <LazyLoad offset={300}>
                  <Box as='iframe' src='https://youtube.com/embed/-3cC0zZ6yt0?si=Myn4-Rtkn6KUggUX' width={{ md:'100%'}}  height='550px'/>
                </LazyLoad>
                <Style.TextWrapper>
                  <Heading 
                  variant="smallheading" 
                  title='FIND OUT MORE' 
                  lineHeight='1'
                  color='white'
                  marginBottom='5px'
                  />
                  
                </Style.TextWrapper>
              </Style.VideoWrapper>
              <Style.VideoWrapper width='100% !important'>
                <LazyLoad offset={300}>
                  <Box as='iframe' src='https://youtube.com/embed/V9AVPmPYwLE?si=AQaDgcJiFYqZntVg' width={{ md:'100%'}}  height='550px'/>
                </LazyLoad>
                <Style.TextWrapper>
                  <Heading 
                  variant="smallheading" 
                  title='BREATHING TECHNIQUE' 
                  lineHeight='1'
                  color='white'
                  marginBottom='5px'
                  />
                </Style.TextWrapper>
              </Style.VideoWrapper>
              <Style.VideoWrapper width='100% !important'>
                <LazyLoad offset={300}>
                  <Box as='iframe' src='https://www.youtube.com/embed/ksFkWySYU3o' width={{ md:'100%'}}  height='550px'/>
                </LazyLoad>
                <Style.TextWrapper>

                  <Heading 
                  variant="smallheading" 
                  title='Post Natal Training at Perpetua Fitness' 
                  lineHeight='1'
                  color='white'
                  marginBottom='5px'
                  />
                </Style.TextWrapper>
              </Style.VideoWrapper>
              <Style.VideoWrapper width='100% !important'>
                <LazyLoad offset={300}>
                  <Box as='iframe' src='https://www.youtube.com/embed/sA49xs5QPj4' width={{ md:'100%'}}  height='550px'/>
                </LazyLoad>
                <Style.TextWrapper>
                  <Heading 
                  variant="smallheading" 
                  title='Post Natal Training at Perpetua Fitness' 
                  lineHeight='1'
                  color='white'
                  marginBottom='5px'
                  />

                </Style.TextWrapper>
              </Style.VideoWrapper>
              
              <Style.VideoWrapper width='100% !important'>
                <LazyLoad offset={300}>
                  <Box as='iframe' src='https://www.youtube.com/embed/So_N0kY0GgY' width={{ md:'100%'}}  height='550px'/>
                </LazyLoad>
                <Style.TextWrapper>
                  <Heading 
                  variant="smallheading" 
                  title='Post Natal at Perpetua Fitness' 
                  lineHeight='1'
                  color='white'
                  marginBottom='5px'
                  />
                </Style.TextWrapper>
              </Style.VideoWrapper>
              <Style.VideoWrapper width='100% !important'>
                <LazyLoad offset={300}>
                  <Box as='iframe' src='https://www.youtube.com/embed/MOpsQsBjz54' width={{ md:'100%'}}  height='550px'/>
                </LazyLoad>
                <Style.TextWrapper>
                  <Heading 
                  variant="smallheading" 
                  title='Hear what our new mamas have to say about joining our Post Natal group' 
                  lineHeight='1'
                  color='white'
                  marginBottom='5px'
                  />
                </Style.TextWrapper>
              </Style.VideoWrapper>

              <Style.VideoWrapper width='100% !important'>
                <LazyLoad offset={300}>
                  <Box as='iframe' src='https://www.youtube.com/embed/9VZSWpsUQ5s' width={{ md:'100%'}}  height='550px'/>
                </LazyLoad>
                <Style.TextWrapper>
                  <Heading 
                  variant="smallheading" 
                  title='Post Natal Training at Perpetua Fitness' 
                  lineHeight='1'
                  color='white'
                  marginBottom='5px'
                  />
                </Style.TextWrapper>
              </Style.VideoWrapper>
              <Style.VideoWrapper width='100% !important'>
                <LazyLoad offset={300}>
                  <Box as='iframe' src='https://www.youtube.com/embed/_aXJpS3dPBA' width={{ md:'100%'}}  height='550px'/>
                </LazyLoad>
                <Style.TextWrapper>
                  <Heading 
                  variant="smallheading" 
                  title='Post Natal Training at Perpetua Fitnes' 
                  lineHeight='1'
                  color='white'
                  marginBottom='5px'
                  />
                </Style.TextWrapper>
              </Style.VideoWrapper>
              <Style.VideoWrapper width='100% !important'>
                <LazyLoad offset={300}>
                  <Box as='iframe' src='https://www.youtube.com/embed/zZR8kdxMx8E' width={{ md:'100%'}}  height='550px'/>
                </LazyLoad>
                <Style.TextWrapper>
                  <Heading 
                  variant="smallheading" 
                  title='Post Natal Training at Perpetua Fitness' 
                  lineHeight='1'
                  color='white'
                  marginBottom='5px'
                  />
                </Style.TextWrapper>
              </Style.VideoWrapper>
              <Style.VideoWrapper width='100% !important'>
                <LazyLoad offset={300}>
                  <Box as='iframe' src='https://www.youtube.com/embed/shPW1bBAY4g' width={{ md:'100%'}}  height='550px'/>
                </LazyLoad>
                <Style.TextWrapper>
                  <Heading 
                  variant="smallheading" 
                  title='Post Natal Training at Perpetua Fitnes' 
                  lineHeight='1'
                  color='white'
                  marginBottom='5px'
                  />
                </Style.TextWrapper>
              </Style.VideoWrapper>
              <Style.VideoWrapper width='100% !important'>
                <LazyLoad offset={300}>
                  <Box as='iframe' src='https://www.youtube.com/embed/WFtl1Deq0ss' width={{ md:'100%'}}  height='550px'/>
                </LazyLoad>
                <Style.TextWrapper>
                  <Heading 
                  variant="smallheading" 
                  title='Post Natal Training at Perpetua Fitnes' 
                  lineHeight='1'
                  color='white'
                  marginBottom='5px'
                  />
                </Style.TextWrapper>
              </Style.VideoWrapper>
            </Style.VideoWrapperNatal>
        </Container>
      </Box>

      <BlogSection catSlug ='prepost-natal' />

      <Flex p={{ sm: '50px 0px', md: '90px 0px' }}>
        <Container maxW={{ sm: 'Container.sm', md: 'container.lg', lg: 'container.lg', xl: 'container.xl' }}>
          <Stack direction={{ sm: 'column', md: 'row' }} spacing='24px'>
            <Box width={{ sm: '100%', md: '50%' }}>
              <Box paddingLeft='30px' position={'relative'} _after={{ position: 'absolute', content: '""', width: '3px', height: '100%', top: '0', left: '0', background: 'Red' }}>
                <Heading title='FREQUENTLY ASKED QUESTIONS' lineHeight='1' padding='0px 0px 30px' margin='0px auto' ></Heading>
                <Text title='Questions about the workout or our gyms? We got you. Check out the FAQ below or contact us.' width='80%' ></Text>
              </Box>
            </Box>
            <Box width={{ sm: '100%', md: '50%' }}>
              <Accordion defaultIndex={[0]} allowMultiple className='Accor_item _black_acording  white_acording'>
                <AccordionItem >
                  <h2>
                    <AccordionButton>
                      <Box as="span" flex='1' textAlign='left' >
                        <Heading title='Who Is This Suitable for Postnatal Training?' variant='extrasmall'></Heading>
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4}>
                    <Text title='1-2-1 Training and Post Natal Group Training is suitable once you have been cleared for exercise (postnatal generally 6 weeks post vaginal birth /12 weeks post caesarean birth).'  />
                  </AccordionPanel>
                </AccordionItem>
                <AccordionItem>
                  <h2>
                    <AccordionButton>
                      <Box as="span" flex='1' textAlign='left' >
                        <Heading title="Can my baby come with me?" variant='extrasmall'></Heading>
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4}>
                    <Text title='Yes, babies are fine to join.  Bring whatever you need to keep them occupied e.g. bouncers, toys etc' margin='0px 0px 20px'></Text>

                  </AccordionPanel>
                </AccordionItem>
                <AccordionItem>
                  <h2>
                    <AccordionButton>
                      <Box as="span" flex='1' textAlign='left' >
                        <Heading title='Is it noisy in the gym?' variant='extrasmall'></Heading>
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4}>
                    <Text title="The gym is still open to members and clients during this time however, the music is always lowered and this tends to be the quietest time." margin='0px 0px 20px'></Text>
                  </AccordionPanel>
                </AccordionItem>
                <AccordionItem>
                  <h2>
                    <AccordionButton>
                      <Box as="span" flex='1' textAlign='left' >
                        <Heading title="Should I have already exercised before attending this group?" variant='extrasmall' />
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4}>
                    <Text title="No, Loz would ideally like this to have been your first time exercising since birth other than walking." />
                  </AccordionPanel>
                </AccordionItem>
                <AccordionItem>
                  <h2>
                    <AccordionButton>
                      <Box as="span" flex='1' textAlign='left' >
                        <Heading title="What If I’m absent for a Postnatal Class?" variant='extrasmall' />
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4}>
                    <Text title='It’s ok to miss classes, however, you cannot make up for them.' margin='0px 0px 20px' />
                    <Text title='Most feel comfortable from 2-3 months postnatal up until their baby is at pre-crawling stage and is suitable for moms with babies up to 12 months.' />
                  </AccordionPanel>
                </AccordionItem>
                <AccordionItem>
                  <h2>
                    <AccordionButton>
                      <Box as="span" flex='1' textAlign='left' >
                        <Heading title="Have You Any Options For Prenatal? " variant='extrasmall' />
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4}>
                    <Text title='Yes, our Prenatal Training is a strength focused class on Thursday evenings from 7-8pm' margin='0px 0px 20px' />
                  </AccordionPanel>
                </AccordionItem>

                <AccordionItem>
                  <h2>
                    <AccordionButton>
                      <Box as="span" flex='1' textAlign='left' >
                        <Heading title="When is the best time to start Prenatal training? " variant='extrasmall' />
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4}>
                    <Text title='The sooner, the better.  It’s important to continue to keep building strength throughout your pregnancy so that you’re able to support your body in the later stages of pregnancy.' margin='0 0 20px' />
                  </AccordionPanel>
                </AccordionItem>
                <AccordionItem>
                  <h2>
                    <AccordionButton>
                      <Box as="span" flex='1' textAlign='left' >
                        <Heading title="Can I use my studio credits for Pre/Postnatal Training?" variant='extrasmall' />
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4}>
                    <Text title='No, this is a separate payment from our studios.' margin='0 0 20px' />
                  </AccordionPanel>
                </AccordionItem>
                <AccordionItem>
                  <h2>
                    <AccordionButton>
                      <Box as="span" flex='1' textAlign='left' >
                        <Heading title="Can I book using Class Pass?" variant='extrasmall' />
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4}>
                    <Text title='No, you can only register for Pre/Postnatal Training through Perpetua.' margin='0 0 20px' />
                  </AccordionPanel>
                </AccordionItem>
                <AccordionItem>
                  <h2>
                    <AccordionButton>
                      <Box as="span" flex='1' textAlign='left' >
                        <Heading title="Can I exercise if I’m pregnant and haven’t exercised before?  " variant='extrasmall' />
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4}>
                    <Text title='Yes, absolutely. We will start you slow and build it up.' margin='0px 0px 20px' />
                  </AccordionPanel>
                </AccordionItem>
                <AccordionItem>
                  <h2>
                    <AccordionButton>
                      <Box as="span" flex='1' textAlign='left' >
                        <Heading title="Where are you located? " variant='extrasmall' />
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4}>
                    <Text title='2 WindMill Lane, Dublin 2' margin='0px 0px 20px' />
                  </AccordionPanel>
                </AccordionItem>
                <AccordionItem>
                  <h2>
                    <AccordionButton>
                      <Box as="span" flex='1' textAlign='left' >
                        <Heading title="What if I’m unsure what suits me Best? " variant='extrasmall' />
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4}>
                    <Text title='Have a consultation with Loz, grab a coffee or arrange a call to help figure out which suits you best.' margin='0px 0px 20px' />
                  </AccordionPanel>
                </AccordionItem>
                
              </Accordion>
            </Box>
          </Stack>
        </Container>
      </Flex>

      <VStack backgroundColor='black' padding={{sm :'50px 0px',md:'50px 20px',lg:'70px 20px',xl:'96px 0px'}} borderBottom='1px solid #232323'>
        <Container 
          display='flex'
          maxW='container.xl'
          flexDirection={{base:'column',lg:'row' }}
          alignItems='top'
          alignContent='center'
          justifyContent='center'
          justifyItems='center'
        >
          <Box w={{base:'100%', lg:'50%'}} textAlign={{base:'center', lg:'left'}} marginBottom={{base:'50px', lg:'0'}}>
              <Heading title='LOOKING FOR MORE INFORMATION' variant='extralarge' lineHeight='1' margin='0px 0px 40px' color='white'></Heading>
              <Heading title='Chat with a member of the team ' variant='smallmid' lineHeight='1' margin='0px 0px 30px' color='white'></Heading>
              <Link href='/contact-us' textDecoration='none' display='inline-block' _hover={{textDecoration:'none' }}>
                  <LinkButton 
                    title='BOOK A CALL' 
                    Imguri='/images/red_arrow.svg' 
                    border='2px solid' borderColor='Red' 
                    padding='20px 25px'
                    color='White'
                  ></LinkButton>
              </Link>
          </Box>
          <Box w={{base:'100%', lg:'50%'}}>
            <ContactForm pageName="PostNatal"></ContactForm>
          </Box>
        </Container>
      </VStack>

    </>
  );
}

export default PostNatal;
