import React, { useEffect, useRef } from 'react';
import MetaTitle from '../pages/MetaTitle';
import { Box, Container, Stack, UnorderedList, ListItem, Flex, SimpleGrid, GridItem, Image, Center, Link } from '@chakra-ui/react';
import Heading from '../component/Heading/Heading';
import Text from '../component/SubHeading/SubHeading';
import * as Style from '../pages/Crossfit.style';
import VideoBanner from '../component/VideoBanner/VideoBanner';
import Button from '../component/Button/CustomeButton';
import { NavLink } from 'react-router-dom';


const HyroxSimulation = () => {

    const metaTitle = "HYROX Simulation Dublin | Perpetua Fitness"
    const metaDesc = "Get ready to dominate the HYROX '24 season. HYROX simulation with Perpetua Fitness in Windmill Lane, Dublin 2."

    const videoEl = useRef(null);
    const videoE2 = useRef(null);
    const attemptPlay = () => {
        videoEl &&
            videoEl.current &&
            videoEl.current.play().catch(error => {
                console.error("Error attempting to play", error);
            });
    };
    const attemptPlay2 = () => {
        videoE2 &&
            videoE2.current &&
            videoE2.current.play().catch(error => {
                console.error("Error attempting to play", error);
            });
    };

    useEffect(() => {
        attemptPlay();
        attemptPlay2();
    }, []);


    return (
        <>
            <MetaTitle title={metaTitle} content={metaDesc} />
            <VideoBanner
                videobanner='../video/Hyrox-banner.mp4'
                title="HYROX SIMULATION DUBLIN"
                subtitle="GET READY TO DOMINATE THE HYROX '25 SEASON"
                isButton={false}
                issubtextpara={false}
                buttontitle="Book a class"
                margin="0px 0 0"
                Link="/timetable"
                videoModal='https://www.youtube.com/embed/IOu_ndmzDKE'
                targetBlank={false}
                modalButtontitle="View The Experience"
                issubtitleRight={false}
            />

            <Box backgroundColor='black' padding={{ sm: '60px 0px', md: '80px 0px 0' }}>
                <Container maxW='container.xl'>
                    <Style.Wrapper>
                        <Style.LeftBox textAlign={{ sm: 'center', md: 'left' }}>
                            <Heading
                                variant="large"
                                title='HYROX Simulation event!'
                                lineHeight='1'
                                color='white'
                            />
                        </Style.LeftBox>
                        <Style.RightBox>
                            <Style.VideoWrapper>
                                <video
                                    style={{ maxWidth: "100%", width: "100%", margin: "0 auto", objectFit: 'cover' }}
                                    playsInline
                                    loop
                                    muted
                                    controls={false}
                                    alt="All the devices"
                                    src='../video/Reel1-HyroxWorkout-Draft2.mp4'
                                    ref={videoEl}
                                />

                            </Style.VideoWrapper>

                            <Style.VideoWrapper>
                                <video
                                    style={{ maxWidth: "100%", width: "100%", margin: "0 auto", objectFit: 'cover', }}
                                    playsInline
                                    loop
                                    muted
                                    controls={false}
                                    alt="All the devices"
                                    src='../video/Reel14-HyroxEdit1.mp4'
                                    ref={videoE2}
                                />
                            </Style.VideoWrapper>
                        </Style.RightBox>
                    </Style.Wrapper>
                </Container>
            </Box>

            <Box padding={{ sm: '0 20px 60px', md: "80px 30px" }} backgroundColor='black'>
                <Container maxW='container.xl' >
                    <Heading title='Are you ready to take on the ultimate challenge? Check out all the details on our upcoming HYROX Simulation event!' variant='medium' textAlign='center' color='#fff' />
                </Container>
            </Box>

            <Container maxW='container.xl' padding={{ sm: '60px 20px 0px', md: '80px 30px 0' }}>
                <Stack direction={{ base: 'column', md: 'row' }} spacing='30px' textAlign={{ base: 'left', lg: 'left' }} alignItems='top' paddingBottom={{ sm: '20px', md: '70px' }}>
                    <Box w={{ lg: '50%' }} position={{ base: 'relative', md: 'sticky' }} top={{ base: '0', md: '100px' }} height={{ base: 'auto', md: '150px' }} textAlign={{ sm: 'center', md: 'left' }}>
                        <Heading title='What is a HYROX Simulation?' lineHeight='1'></Heading>
                    </Box>
                    <Box width={{ md: '50%' }}>
                        <Text title='HYROX is a global fitness competition that combines running and functional workouts. Participants tackle eight alternating rounds of a 1km run and a challenging workout station. Held indoors, HYROX offers an electric atmosphere with spectators cheering you on every step of the way. The same format worldwide creates a level playing field, leading to global rankings and a culminating World Championship. From elite athletes to fitness enthusiasts, HYROX is a thrilling challenge for everyone.' fontSize='16px' />

                        <Text title="A HYROX Simulation is designed to replicate the intense physical and mental demands of a real HYROX competition. It's your chance to test your limits, push your boundaries, and experience the adrenaline rush of HYROX, all in a controlled environment. " fontSize='16px' padding='10px 0' />

                        <Text title="Unlike our classes, these simulations are run in heats meaning we can provide more of a race experience. Simulations last around 60-75 minutes." fontSize='16px' />

                    </Box>
                </Stack>
            </Container>

            <Container maxW='container.xl'>
                <SimpleGrid
                    columns={{ sm: "1", md: "2" }}
                    columnGap={10}
                    rowGap={2}
                    padding={{ sm: "25px 5px", md: "50px 0" }}
                    alignItems='center'
                    justifyContent='center'
                >
                    <GridItem>
                        <Image src='./images/hyroxSimulation_img3.webp' margin="0 auto" />
                    </GridItem>
                    <GridItem padding={{ sm: "20px 0 0", md: "0" }}>
                        <Heading title='Why Participate?' variant='large' />
                        <UnorderedList padding={{ sm: "15px 0", md: "20px 0" }} >
                            <ListItem fontFamily='Poppins' fontWeight='500' fontSize='16px'> <span style={{ fontWeight: '700', fontSize: '18px' }}>Prepare for HYROX: </span> Get a taste of the HYROX experience and identify areas for improvement. </ListItem>
                            <ListItem fontFamily='Poppins' fontWeight='500' fontSize='16px' paddingTop='10px'><span style={{ fontWeight: '700', fontSize: '18px' }}>Build Endurance and Strength: </span> Challenge your body and build the stamina needed for HYROX.</ListItem>
                            <ListItem fontFamily='Poppins' fontWeight='500' fontSize='16px' paddingTop='10px' ><span style={{ fontWeight: '700', fontSize: '18px' }}>Compete: </span>  Test your fitness against others.  </ListItem>
                            <ListItem fontFamily='Poppins' fontWeight='500' fontSize='16px' paddingTop='10px'><span style={{ fontWeight: '700', fontSize: '18px' }}>Community and Support: </span>  Join a community of like-minded athletes and cheer each other on.   </ListItem>
                        </UnorderedList>
                    </GridItem>
                </SimpleGrid>
                <Flex direction={{ sm: "column-reverse", md: "row" }} padding={{ sm: "25px 5px", md: "50px 0" }}>
                    <Box padding={{ sm: "20px 0 0", md: "0" }} width={{md:"50%"}}>
                        <Heading title='Our Next Simulation Event' variant='large' />
                        <UnorderedList padding={{ sm: "15px 0", md: "20px 0" }} >
                            <ListItem fontFamily='Poppins' fontWeight='500' fontSize='16px'> <span style={{ fontWeight: '700', fontSize: '18px' }}>Date: </span> Sunday, April 6<sup>th</sup>. </ListItem>
                            <ListItem fontFamily='Poppins' fontWeight='500' fontSize='16px' paddingTop='10px'><span style={{ fontWeight: '700', fontSize: '18px' }}>Time: </span> Heats Starting at 11:15am.</ListItem>
                            <ListItem fontFamily='Poppins' fontWeight='500' fontSize='16px' paddingTop='10px' ><span style={{ fontWeight: '700', fontSize: '18px' }}>Location: </span>  Windmill Lane.  </ListItem>
                            <ListItem fontFamily='Poppins' fontWeight='500' fontSize='16px' paddingTop='10px' ><span style={{ fontWeight: '700', fontSize: '18px' }}>Next Simulation: </span> Sunday, May 18<sup>th</sup>.  </ListItem>
                        </UnorderedList>
                    </Box>
                    <Box width={{md:"50%"}}>
                        <Image src='./images/hyroxSimulation_img1.webp' margin="0 auto" />
                    </Box>
                </Flex>
                <SimpleGrid
                    columns={{ sm: "1", md: "2" }}
                    columnGap={10}
                    rowGap={2}
                    padding={{ sm: "25px 5px 50px", md: "50px 0 50px" }}
                    alignItems='center'
                    justifyContent='center'
                >
                    <GridItem>
                        <Image src='./images/hyroxSimulation_img5.webp' margin="0 auto" />
                    </GridItem>
                    <GridItem padding={{ sm: "20px 0 0", md: "0" }}>
                        <Heading title='How to Participate:' variant='large' />
                        <UnorderedList padding={{ sm: "15px 0", md: "20px 0" }} >
                            <ListItem fontFamily='Poppins' fontWeight='500' fontSize='16px'> <span style={{ fontWeight: '700', fontSize: '18px' }}>Choose Your Category </span> Compete as an Individual or Doubles. </ListItem>
                            <ListItem fontFamily='Poppins' fontWeight='500' fontSize='16px' paddingTop='10px'><span style={{ fontWeight: '700', fontSize: '18px' }}>Purchase Your Credit: </span> Use a specific HYROX Simulation Credit to book your heat time. If you're competing as a Double, one partner should purchase a Doubles Credit for both of you.</ListItem>
                            <ListItem fontFamily='Poppins' fontWeight='500' fontSize='16px' paddingTop='10px' ><span style={{ fontWeight: '700', fontSize: '18px' }}>Book Your Heat: </span>  Select your preferred heat time using your purchased credit.  </ListItem>
                        </UnorderedList>
                    </GridItem>
                </SimpleGrid>
                <Flex direction={{ sm: "column-reverse", md: "row" }} alignItems={{md:"center"}} padding={{ sm: "25px 5px 50px", md: "25px 0 70px" }}>
                    <Box padding={{ sm: "20px 0 0", md: "0" }} width={{md:"50%"}}>
                        <Heading title='HOW TO PREPARE' variant='large' />
                        <Text title="We have HYROX Classes every day of the week, so that you can build your engine and prime your strength for a HYROX race or simulation. Our programming is designed by coaches with years of experience taking on these races, so you'll build the fitness that you need and learn from some of the best in the industry. Every day is different; some days are more strength-focused and some are all out conditioning." padding='10px 0 25px' width={{md:"85%"}} />
                        <NavLink to='/hyrox-dublin'>
                            <Button
                                title='HYROX CLASSES'
                                color='#000'
                            />
                        </NavLink>
                    </Box> 
                    <Box width={{md:"50%"}}>
                        <Image src='./images/hyroxSimulation_img4.webp' margin="0 auto" />
                    </Box>
                </Flex>
            </Container>
            <Box padding={{sm:"40px 0", md:"40px 0"}} backgroundColor='#000'>
                <Container maxW='container.xl'>
                    <Center gap={5} flexDirection={{base:"column", sm:"column", md:"row"}} justifyContent='center'>
                        <Link href='https://clients.mindbodyonline.com/app/business/classic/admmainclass' isExternal={true}>
                            <Button
                                title='Individual Heats'
                                color='#fff'
                            />
                        </Link>
                        <Link href='https://clients.mindbodyonline.com/app/business/classic/admmainclass' isExternal={true}>
                            <Button
                                title='Doubles Heat'
                                color='#fff'
                            />
                        </Link>
                    </Center>
                </Container>
            </Box>
            <Box padding={{sm:"50px 0", md:"70px 0"}}>
                <Container maxW='container.xl'>
                    <Heading title='Read Our Blogs' lineHeight='1' textAlign='center' />
                    <SimpleGrid
                        columns={{sm:"1", md:"2"}}
                        gap='10px'
                        paddingTop={{sm:"20px", md:"30px"}}
                        alignItems='center'
                        justifyContent='center'
                    >
                        <GridItem>
                            <Center flexDirection='column' gap='25px'>
                                <NavLink to='/blog/how-to-be-strategic-in-a-hyrox-race'>
                                    <Image src='./images/hyroxSimulation_2.webp' margin="0 auto" />
                                </NavLink>
                                <NavLink to='/blog/how-to-be-strategic-in-a-hyrox-race'>
                                    <Button
                                        title='Hyrox Race'
                                        color='#000'
                                    />
                                </NavLink>
                            </Center>
                        </GridItem>

                        <GridItem paddingTop={{ sm: "20px", md: "0" }}>
                            <Center flexDirection='column' gap='25px'>
                                <NavLink to='/blog/preparing-for-the-hyrox-dublin-race-a-practical-guide'>
                                    <Image src='./images/hyroxSimulation_1.webp' margin="0 auto" />
                                </NavLink>
                                <NavLink to='/blog/preparing-for-the-hyrox-dublin-race-a-practical-guide'>
                                    <Button
                                        title='Hyrox Dublin Race'
                                        color='#000'
                                    />
                                </NavLink>
                            </Center>
                        </GridItem>

                    </SimpleGrid>
                </Container>
            </Box>
        </>
    )
}

export default HyroxSimulation;