import React from 'react';
import { Box, Container, SimpleGrid, ListItem, UnorderedList, GridItem , Heading as Heading2,} from '@chakra-ui/react';
import InnerBanner from '../component/InnerBanner/InnerBanner';
import Text from '../component/SubHeading/SubHeading';
import Heading from '../component/Heading/Heading';
import MetaTitle from '../pages/MetaTitle';
import PriceCard3 from '../component/Pricing/PriceCard3';

const StudioMembership = () => {
    return (
        <>
            <MetaTitle title='Studio Membership | Perpetua Fitness' />
            <InnerBanner
                backgroundColor='black'
                backgroundImage={{ sm: 'url(./images/Homenew_mb.webp)', md: 'url(./images/StudioMembershipWebsiteBannerApril251.webp)' }}
                isHeading={false}
                issubtextpara={false}
                isButton={false}
                isButton2={false}
                issubtitleRight={false}
                isSubtext={false}
                width={{ sm: '100%', md: '100%' }}
                height={{ sm: '450px', md: '500px', lg: '550px', xl: '767px' }}
            />
            <Box padding={{ sm: "50px 0", md: "40px 0 80px", xl:"40px 0 80px" }}>
                <Container maxW='container.xl'>
                    <Heading title='CHOOSE YOUR MEMBERSHIP' variant='large' textAlign='center' padding='0 0 10px' />

                    <Box  padding={{sm:"50px 0 10px",md:'50px 0 10px'}}>
                        <Heading title='TRAIN FOR LESS' variant='extrasmall' />
                        <Heading2 paddingTop='20px' textTransform="uppercase" letterSpacing='0px' fontSize={{sm:"30px", md:"60px", lg:"90px"}} lineHeight= "76%" fontFamily='Poppins'>STUDIO MEMBERSHIPS  </Heading2>
                    </Box>
                    
                    <SimpleGrid columns={{ sm: "1", md: "2", xl: "5" }} spacing={10} margin='30px 0 0'>
                        
                        <GridItem>
                            <Box color='#D12C41' className='_icon' fontSize={{ sm: "20px", md: "30px" }} ><i class="bi bi-tag-fill"></i></Box> 
                            <Heading title='Train For Less' variant='smallmid' padding='10px 0' />
                            <Text title='Our studio memberships work out much cheaper for our to attend our studio class.' color='#000' />
                        </GridItem>
                        <GridItem>
                            <Box color='#D12C41' className='_icon' fontSize={{ sm: "20px", md: "30px" }}><i class="bi bi-trophy"></i></Box>  
                            <Heading title='Access To ALL Studio Class' variant='smallmid' padding='10px 0' />
                            <Text title='Attend Tread & Shred, Rumble, Rythm Ride, Ride 45 & HYROX class.' color='#000' />
                        </GridItem>
                        <GridItem>
                            <Box color='#D12C41' className='_icon' fontSize={{ sm: "20px", md: "30px" }}><i class="bi bi-lightning-charge"></i></Box>  
                            <Heading title='Train With A Buddy' variant='smallmid' padding='10px 0' />
                            <Text title='Want to train with your friends? Invite them to join a class with us.' color='#000' />
                        </GridItem>
                        <GridItem>
                            <Box color='#D12C41' className='_icon' fontSize={{ sm: "20px", md: "30px" }}><i class="bi bi-snow2"></i></Box> 
                            <Heading title='Freeze Your Membership' variant='smallmid' padding='10px 0' />
                            <Text title='Commit to our 6-month membership and you can freeze your membership once every
                            6 months.' color='#000' />
                        </GridItem>
                        <GridItem>
                            <Box color='#D12C41' className='_icon' fontSize={{ sm: "20px", md: "30px" }}><i class="bi bi-calendar-check"></i></Box> 
                            <Heading title='EARLY ACCESS BOOKING' variant='smallmid' padding='10px 0' />
                            <Text title='Perpetua members will enjoy an exclusive 6-day booking window, meaning you can get in and book your class a day before everyone else and secure your workout.' color='#000' />
                        </GridItem>
                    </SimpleGrid>

                    <Box>
                        <Heading title='3 Month Commitment ' textAlign='center' padding='35px 0 ' variant='medium' color='#D12C41' /> 
                        <SimpleGrid columns={{ sm: "1", md: "2", xl: "2", xxl: "3" }} spacing={{ sm: '5', md: '10' }}>
                            <PriceCard3 key="10" StudioMembershipOffer='true' listItem='true' description='' offer="€21 per Credit" currency="€168" currencyMembershipOffer='€117.60' MembershipOfferPeriod='For your First month' planname="STUDIO 8" credit="€21" guest="1 Guest Pass" link=" https://clients.mindbodyonline.com/classic/ws?studioid=535079&stype=40&prodId=181" targetBlank='true' buttonTitle='Buy Now' />

                            <PriceCard3 key="11" StudioMembershipOffer='true'  listItem='true' description='' offer="€18 per Credit" cardTitle="" currency="€216" currencyMembershipOffer='€151.20' MembershipOfferPeriod='For your First month' planname="STUDIO 12" credit="€21" guest="2 Guest Passes" link="https://clients.mindbodyonline.com/classic/ws?studioid=535079&stype=40&prodId=179" targetBlank='true' buttonTitle='Buy Now' />
                            
                            <PriceCard3 key="12" StudioMembershipOffer='true'  listItem='true' description='' offer="€16.87 per Credit" cardTitle="" currency="€270" currencyMembershipOffer='€189' MembershipOfferPeriod='For your First month' planname="STUDIO 16" credit="€19" guest="2 Guest Passes" link="https:/clients.mindbodyonline.com/classic/ws?studioid=535079&stype=40&prodId=183" targetBlank='true' buttonTitle='Buy Now' />
                        </SimpleGrid>
                    </Box>
                    <Box>
                        <Heading title='6 Month Commitment ' textAlign='center' padding='35px 0 ' variant='medium' color='#D12C41' /> 
                        <SimpleGrid columns={{ sm: "1", md: "2", xl: "2", xxl: "3" }} spacing={{ sm: '5', md: '10' }}>

                            <PriceCard3 key="13"  StudioMembershipOffer='true' listItem='true' description='' offer="€19 per Credit" cardTitle="" currency="€152" currencyMembershipOffer='€106.40' MembershipOfferPeriod='For your First month' planname="STUDIO 8" credit="€20" guest="1 Guest Pass" link="https://clients.mindbodyonline.com/classic/ws?studioid=535079&stype=40&prodId=167" targetBlank='true' freeze='Freeze your membership'icon="bi bi-snow2" buttonTitle='Buy Now' inbodyScan='1 Complimentary Inbody Scan' />

                            <PriceCard3 key="14"  StudioMembershipOffer='true' listItem='true' description='' offer="€16.83 per Credit" cardTitle="" currency="€202" currencyMembershipOffer='€141.40' MembershipOfferPeriod='For your First month' planname="STUDIO 12" credit="€18" guest="2 Guest Passes" link="https://clients.mindbodyonline.com/classic/ws?studioid=535079&stype=40&prodId=182" targetBlank='true' freeze='Freeze your membership' icon="bi bi-snow2" buttonTitle='Buy Now' inbodyScan='1 Complimentary Inbody Scan' />

                            <PriceCard3 key="15"  StudioMembershipOffer='true' listItem='true' description='' offer="€15 per Credit" cardTitle="" currency="€240" currencyMembershipOffer='€168' MembershipOfferPeriod='For your First month' planname="STUDIO 16" credit="€17" guest="2 Guest Passes" link="https://clients.mindbodyonline.com/classic/ws?studioid=535079&stype=40&prodId=169" targetBlank='true' freeze='Freeze your membership' icon="bi bi-snow2"  buttonTitle='Buy Now' inbodyScan='1 Complimentary Inbody Scan' />
                        </SimpleGrid>
                    </Box>

                    <Box>
                        <Heading title='12 Month Commitment ' textAlign='center' padding='35px 0 ' variant='medium' color='#D12C41' /> 
                        <SimpleGrid columns={{ sm: "1", md: "2", xl: "2", xxl: "3" }} spacing={{ sm: '5', md: '10' }}>

                            <PriceCard3 key="13"  StudioMembershipOffer='true' listItem='true' description='' offer="€17.50 per Credit" cardTitle="" currency="€140" currencyMembershipOffer='€98' MembershipOfferPeriod='For your First month' planname="STUDIO 8" credit="€19" guest="1 Guest Pass" link="https://clients.mindbodyonline.com/classic/ws?studioid=535079&stype=40&prodId=175" targetBlank='true' freeze='Freeze your membership'icon="bi bi-snow2" buttonTitle='Buy Now' inbodyScan='2 Complimentary Inbody Scan' />

                            <PriceCard3 key="14"  StudioMembershipOffer='true' listItem='true' description='' offer="€16 per Credit" cardTitle="" currency="€192" currencyMembershipOffer='€134.40' MembershipOfferPeriod='For your First month' planname="STUDIO 12" credit="€17" guest="2 Guest Passes" link="https://clients.mindbodyonline.com/classic/ws?studioid=535079&stype=40&prodId=176" targetBlank='true' freeze='Freeze your membership' icon="bi bi-snow2" buttonTitle='Buy Now' inbodyScan='2 Complimentary Inbody Scan' />

                            <PriceCard3 key="15"  StudioMembershipOffer='true' listItem='true' description='' offer="€14 per Credit" cardTitle="" currency="€224" currencyMembershipOffer='€156.80' MembershipOfferPeriod='For your First month' planname="STUDIO 16" credit="€15" guest="2 Guest Passes" link="https://clients.mindbodyonline.com/classic/ws?studioid=535079&stype=40&prodId=177" targetBlank='true' freeze='Freeze your membership' icon="bi bi-snow2"  buttonTitle='Buy Now' inbodyScan='2 Complimentary Inbody Scan' />
                        </SimpleGrid>
                    </Box>
                </Container>
            </Box>
            <Box backgroundColor='#F4F4F3'  >
                <Container maxW='container.xl' padding={{ sm: "50px 15px", md: "70px 0 80px" }}  >
                    <Heading title='Terms & Conditions: ' variant='large'  />
                    
                    <Text title='Discount will be applied to first month of membership, subsequent payments will be charged at the standard rate.' fontSize='18px' padding='15px 0 0' fontWeight='500' />
                    <Text title='Payment will be taken every 30 days from the card on the client’s account for the agreed contract term.' fontSize='18px' padding='10px 0 0' fontWeight='500' />
                    <Text title='Standard studio membership T&C’s apply and will be communciated to you upon commencement of your membership.' fontSize='18px' padding='10px 0 0' fontWeight='500' />
                </Container>
            </Box>
        </>
    )
}

export default StudioMembership;
