import React, { useEffect, useState } from 'react';
import {
  Flex, Container, SimpleGrid, GridItem, Box, Link, Image, Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  Center,
} from '@chakra-ui/react';
import Heading from '../component/Heading/Heading';
import ClassCard from '../component/Classcard/ClassCard';
import AppDownload from '../component/AppDownload/AppDownload';
import Text from '../component/SubHeading/SubHeading';
import Button from '../component/Button/CustomeButton';
import Testimonial from '../component/Testimonial/Testimonial';
import Accordian from '../component/Accordion/Accordian';
import MetaTitle from './MetaTitle';
import WOW from 'wowjs';
import VideoSection from '../component/VideoSection/VideoSection';
import VideoBanner from '../component/VideoBanner/VideoBanner';
import BlogSection from '../component/Blog/BlogSection';
import { NavLink } from 'react-router-dom';
import { MinusIcon, AddIcon } from '@chakra-ui/icons'
import axios from 'axios';
import TabItem from '../component/TeamFilter/TabItem';
import LazyLoad from "react-lazy-load";
import * as Style from './Crossfit.style';

const accoData = [
  {
    "id": 1,
    "title": "When should I arrive for class?",
    "description": "We recommend arriving at the gym 15 minutes before class for check-in. This will allow time for our Front Desk staff to get you checked into class and show you around the facility.",
  },
  {
    "id": 2,
    "title": "What do I do when I get there?",
    "description": "Say hello to whoever is at the front desk and mention that it is your first time. They’ll get you taken care of.",
  },
  {
    "id": 3,
    "title": "What if I'm running late?",
    "description": "To ensure that your spot is not released to the waitlist, please arrive at least 5 minutes prior to the start of your class.",
  },
  {
    "id": 3,
    "title": "Good Vibes Zone",
    "description": "We want you to bring your good vibes! We truly believe in making our gyms a space where members can check their stress at the door. In our gyms you’ll find a community built on sweat and support, all are welcome here. All we ask is that you show up, bring a smile, and get in a great workout. It’s that simple.",
  }

]
const accoData2 = [
  {
    "id": 1,
    "title": "What is a Tread and Shred class like?",
    "description": "Our signature Tread&Shred class is a group fitness experience unmatched in Ireland. You’ll alternate between treadmill and floor rounds focused on strength, power, and endurance. You’ll torch calories and get stronger and faster every class.",
  },
  {
    "id": 2,
    "title": "I've never done this style of training before, can I keep up?",
    "description": "Absolutely! Our workouts are beginner friendly and designed to allow all athletes to workout at their own pace. Our Coaches are trained to modify and make adjustments to the workout on the fly to meet you where you are.",
  },
  {
    "id": 3,
    "title": "What should I wear?",
    "description": "Wear athletic clothes that are comfy for you to sweat and move in.",
  },
  {
    "id": 3,
    "title": "What shoes should I wear?",
    "description": "Most athletes wear traditional cross-training shoes suitable for cardio & strength training.",
  },
  {
    "id": 3,
    "title": "Do you guys provide water?",
    "description": "Each of our gyms are equipped with filtered water stations. Bring a reusable water bottle with you and fill up before class!",
  }

]
const accoData3 = [
  {
    "id": 1,
    "title": "Do you have showers?",
    "description": "Yes! Each of the gyms have showers that you are free to use. Towels and bath & body products are also provided.",
  },
  {
    "id": 2,
    "title": "That was fun! What's next?",
    "description": "Awesome! Schedule your next workout through the Perpetua Fitness app. If you have any questions, please feel free to reach out to our team at any facility or through hello@perpetua.ie",
  }

]

const metaTitle = "Tread & Shred | Perpetua Fitness"
const metaDesc = "Fitness Classes in Dublin with Perpetua Fitness. Our signature Tread & Shred class is a group fitness experience unmatched in Ireland. Book a class."



function SweatPage(props) {

  useEffect(() => {
    new WOW.WOW({
      live: true,
      offset: 0,
      mobile: true,
    }).init();
  }, [])

  const [items, setItems] = useState([]);
  axios({
      method: 'get',
      url: process.env.REACT_APP_ADMIN_API_BASE_URL + '/coaches/all',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json'
      },

    }).then(response => {
      var rawItems = response.data.data.data;
      console.log(rawItems);
      var filteredItems = rawItems.filter(item => item.category_title.includes('TREAD & SHRED'));
      var limitedItems = filteredItems.slice(0, 4); // Get only the first 4 items
      setItems(limitedItems);
      // setItems(filteredItems);
      // setItems(rawItems);
    }).catch(error => {
      console.log(error);
    });

  return (
    <>
      <MetaTitle title={metaTitle} content={metaDesc} />

      <VideoBanner
        videobanner='../video/T&S-banner.mp4'
        subtitle='FITNESS CLASS DUBLIN'
        title='TREAD AND SHRED'
        subtitleRight={<Box display='inline-block'><svg style={{ display: 'inline-block', top: '12px', position: 'relative', }} display='inline-block' top='12px' position='relative' xmlns="http://www.w3.org/2000/svg" width="38" height="38" viewBox="0 0 38 38" fill="none">
          <path d="M18.9993 10.2917C20.0492 10.2917 21.056 10.7087 21.7983 11.451C22.5406 12.1934 22.9577 13.2002 22.9577 14.25C22.9577 14.7698 22.8553 15.2845 22.6564 15.7648C22.4574 16.245 22.1659 16.6814 21.7983 17.049C21.4307 17.4165 20.9944 17.7081 20.5141 17.907C20.0339 18.1059 19.5192 18.2083 18.9993 18.2083C17.9495 18.2083 16.9427 17.7913 16.2004 17.049C15.4581 16.3066 15.041 15.2998 15.041 14.25C15.041 13.2002 15.4581 12.1934 16.2004 11.451C16.9427 10.7087 17.9495 10.2917 18.9993 10.2917ZM18.9993 3.16666C21.9388 3.16666 24.7579 4.33436 26.8364 6.41289C28.915 8.49142 30.0827 11.3105 30.0827 14.25C30.0827 22.5625 18.9993 34.8333 18.9993 34.8333C18.9993 34.8333 7.91602 22.5625 7.91602 14.25C7.91602 11.3105 9.08372 8.49142 11.1622 6.41289C13.2408 4.33436 16.0599 3.16666 18.9993 3.16666ZM18.9993 6.33332C16.8997 6.33332 14.8861 7.1674 13.4014 8.65206C11.9168 10.1367 11.0827 12.1504 11.0827 14.25C11.0827 15.8333 11.0827 19 18.9993 29.6242C26.916 19 26.916 15.8333 26.916 14.25C26.916 12.1504 26.0819 10.1367 24.5973 8.65206C23.1126 7.1674 21.099 6.33332 18.9993 6.33332Z" fill="white" />
        </svg> Available at our Windmill Lane & Lennox Street Facilities</Box>}
        isButton={true}
        issubtextpara={false}
        buttontitle="BOOK A CLASS"
        margin="0px 0 0"
        Link="/timetable"
        videoModal='https://www.youtube.com/embed/b8PWlDPQo0U'
        targetBlank={false}
        modalButtontitle="View The Experience"
        issubtitleRight={true}
      />

      <Flex flexDirection='column' padding={{ sm: '0', md: '50px 0px 60px', lg: '70px 20px 70px', xl: '80px 0px 80px' }} background='#000' marginBottom={{sm:"50px",md:'100px'}}>
        <Container maxW='container.xl'>
          <Heading
            as="h3"
            title="INTELLIGENT PROGRAMMING, WORLD CLASS COACHES, & THE PERFECT BEATS. OUR SIGNATURE TREAD & SHRED CLASS IS A GROUP 
            FITNESS EXPERIENCE UNMATCHED IN IRELAND. YOU’LL ALTERNATE BETWEEN TREADMILL AND FLOOR EXERCISES THAT ARE FOCUSED ON STRENGTH, POWER, AND ENDURANCE. YOU’LL BURN CALORIES AND GET STRONGER AND FASTER EVERY CLASS."
            variant='medium'
            textAlign="center"
            lineHeight="1"
            width={{ lg: '100%', xl: '100%' }}
            padding={{ sm: '40px 0px', md: '0px 0px 70px' }}
            className='wow animate fadeInUp'
            color='#fff'
          >
          </Heading>
        </Container>
        <Container maxW='container-fluid'>
          <SimpleGrid
            columns={{ sm: '1', md: '2', lg: '2', xl: '3', xxl: '4' }}
            columnGap={4}
            rowGap={2}
            width="full"
            marginTop='50px'
            padding={{ sm: '0px', md: '0px 30px' }}
          >
            <GridItem className="wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.7s">
              <ClassCard
                title='WARM UP'
                subtitle='Please arrive 15 minutes early and check in with our front desk. Our staff will introduce
                        you to the class coach. They will provide an introduction to how the class works and show you how to use our treadmills. We have 6-10 minutes set aside to start your session off with a dynamic warm-
                        up to prep your body for the activity to come, we will alternate between the treadmills and floor until your heart rate is up and your body is ready to go!'
                bg="url('/images/TreadAndShread/WarmUp.webp')"
                isButton={false}
                marginBottom='30px'
                transform="uppercase"
                minHeight={{ sm: '450px', md: '450px', lg: '450px', xl: '500px', xxl: '600px' }}
              />
            </GridItem>
            <GridItem mt={{ sm: '40px', xl: '80px' }} className="wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.9s">
              <ClassCard
                title='TREADMILL'
                subtitle='LACE UP YOUR TRAINERS AND GET READY TO WORK. YOUR COACH WILL GUIDE YOU THROUGH THE LIKES OF LUNG BUSTING SPRINTS, INCLINE INTERVALS AND ENERGY SAPPING SLED PUSHES. NOT A RUNNER? NO BOTHER. YOUR COACH WILL GUIDE YOU EVERY STEP OF THE WAY - BEGINNER, INTERMEDIATE, AND ADVANCED RUNNERS OUT THERE, WE HAVE YOU COVERED!'
                bg="url('/images/TreadAndShread/Treadmill.webp')"
                isButton={false}
                marginBottom='30px'
                transform="uppercase"
                minHeight={{ sm: '450px', md: '450px', lg: '450px', xl: '500px', xxl: '600px' }}
              />
            </GridItem>
            <GridItem mt={{ sm: '0', lg: '0', xl: '160px' }} className="wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.9s">
              <ClassCard
                title='FLOOR WORK'
                subtitle='Let’s get spicy. Our floor rounds incorporate a variety of movements and equipment, including boxes, trx ropes, and our signature torpedoes. Floor rounds are designed to maximise your strength and agility, complimenting the work you’re doing on the tread.'
                bg="url('/images/TreadAndShread/Floorwork.webp')"
                isButton={false}
                marginBottom='30px'
                transform="uppercase"
                minHeight={{ sm: '450px', md: '450px', lg: '450px', xl: '500px', xxl: '600px' }}
              />
            </GridItem>
            <GridItem mt={{ sm: '0', md: '40px', lg: '40px', xl: '0', xxl: '240px' }} className="wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.9s">
              <ClassCard
                title='COOL DOWN & HIGH FIVES'
                subtitle='We have 5 minutes set aside to finish class with a cool-down to bring your heart rate back
                        to normal and stretch out your muscles. Afterward, you can shower on-site & grab a shake at our
                        Mojo Health Bar'
                bg="url('/images/TreadAndShread/Cooldown.webp')"
                isButton={false}
                marginBottom='30px'
                transform="uppercase"
                minHeight={{ sm: '450px', md: '450px', lg: '450px', xl: '500px', xxl: '600px' }}
              />
            </GridItem>
          </SimpleGrid>
        </Container>
      </Flex>

      <Flex flexDirection='column' padding={{ sm: '0px 5px 70px', md: '0 30px 50px', lg: "0 60px 50px" }}>
        <Container maxW='container.xxl'>
          <Box
            columnGap={10}
            rowGap={2}
            flexDirection={{ xl: "row" }}
            display={{ xl: "flex" }}
          >
            <Box
              width={{ xl: '30%' }}
            >
              <Heading
                as="h2"
                title="WHO IS TREAD AND SHRED FOR?"
                variant='extralarge'
                textAlign={{ sm: "center", md: "left" }}
                lineHeight="1"
                padding='0px 0px 20px'
              />
              <Text
                as="p"
                fontFamily='poppins'
                title='Our signature Tread & Shred class is a group fitness experience unmatched in Ireland. It is tailored towards complete beginners as well as professional athletes.You’ll alternate between treadmill and floor exercises focused on strength, power, and endurance. You’ll burn calories and get stronger and faster every class.'
                width='98%'
              />
              <Heading
                as="h4"
                title="WHO IS TREAD AND SHRED FOR?"
                variant='extrasmall'
                textAlign={{ sm: "center", md: "left" }}
                lineHeight="1"
                width='102%'
                padding={{ base: '20px 0px 15px', sm: '30px 0px' }}
              />
              <Link href='/pricing' _hover={{ textDecoration: 'none' }} display='flex' justifyContent={{ sm: 'center', md: "flex-start" }}><Button title='Get Started' className="wow fadeInUp" color='black' /> </Link>
            </Box>
            <Box
              width={{ xl: '70%' }}
              paddingTop={{ sm: "50px", xl: "0" }}
            >
              <Accordion allowToggle>
                <AccordionItem>
                  {({ isExpanded }) => (
                    <>
                      <AccordionButton padding='15px 10px'>
                        <Box className='accHeading' as="span" flex='1' textAlign='left' paddingRight="10px">
                          <Heading title='FULL BODY | INTENSITY' variant='extrasmall' />
                        </Box>
                        <Flex width={{ md: '60%' }}>
                          <Box display={{ sm: "none", md: "block" }} className='accHeading' as="span" flex='1' textAlign='left' paddingRight="10px">
                            <Heading title='INTENSITY' variant='smallheading' color='#c79d5b' />
                            <Flex paddingTop='10px'>
                              <i class="bi bi-egg-fill" style={{ fontSize: '14px' }}></i>
                              <i class="bi bi-egg-fill" style={{ fontSize: '14px' }}></i>
                              <i class="bi bi-egg-fill" style={{ fontSize: '14px' }}></i>
                            </Flex>
                          </Box>
                          <Box display={{ sm: "none", md: "block" }} className='accHeading' as="span" flex='1' textAlign='left' paddingRight="10px">
                            <Heading title='‘Strength' variant='smallheading' color='#c79d5b' />
                            <Flex paddingTop='10px'>
                              <i class="bi bi-egg-fill" style={{ fontSize: '14px' }}></i>
                              <i class="bi bi-egg" style={{ fontSize: '14px' }}></i>
                              <i class="bi bi-egg" style={{ fontSize: '14px' }}></i>
                            </Flex>
                          </Box>
                          <Box display={{ sm: "none", md: "block" }} className='accHeading' as="span" flex='1' textAlign='left' paddingRight="10px">
                            <Heading title='DURATION' variant='smallheading' color='#c79d5b' />
                            <Text title='45 mins' textTransform='lowercase' fontSize='14px' padding='10px 0 0' />
                          </Box>
                          <Box display={{ sm: "none", md: "block" }} className='accHeading' as="span" flex='1' textAlign='left' paddingRight="10px">
                            <Heading title='LEVELS' variant='smallheading' color='#c79d5b' />
                            <Text title='All levels' textTransform='capitalize' fontSize='14px' padding='10px 0 0' />
                          </Box>
                          {isExpanded ? (
                            <MinusIcon fontSize={{ sm: "10px", md: "12px" }} />
                          ) : (
                            <AddIcon fontSize={{ sm: "10px", md: "12px" }} />
                          )}
                        </Flex>
                      </AccordionButton>

                      <AccordionPanel pb={4}>
                        <Box marginLeft={{ md: "40%" }}>
                          <Text fontSize={{ sm: '14px', md: '16px' }} title="We're bringing the intensity to our full body exercises, but don't worry, we'll still make sure you work on getting your body stronger. This class will have lots of reps and will improve your overall performance while we burn more calories." />
                          <Flex display={{ sm: "flex", md: "none" }} alignItems='center' justifyContent='space-between' paddingTop='20px' flexWrap='wrap'>
                            <Box className='accHeading'>
                              <Heading title='INTENSITY' variant='smallheading' color='#c79d5b' />
                              <Flex paddingTop='10px'>
                                <i class="bi bi-egg-fill" style={{ fontSize: '14px' }}></i>
                                <i class="bi bi-egg-fill" style={{ fontSize: '14px' }}></i>
                                <i class="bi bi-egg-fill" style={{ fontSize: '14px' }}></i>
                              </Flex>
                            </Box>
                            <Box className='accHeading'>
                              <Heading title='‘Strength' variant='smallheading' color='#c79d5b' />
                              <Flex paddingTop='10px'>
                                <i class="bi bi-egg-fill" style={{ fontSize: '14px' }}></i>
                                <i class="bi bi-egg" style={{ fontSize: '14px' }}></i>
                                <i class="bi bi-egg" style={{ fontSize: '14px' }}></i>
                              </Flex>
                            </Box>
                            <Box className='accHeading'>
                              <Heading title='DURATION' variant='smallheading' color='#c79d5b' />
                              <Text title='45 mins' textTransform='lowercase' fontSize='14px' padding='10px 0 0' />
                            </Box>
                            <Box className='accHeading'>
                              <Heading title='LEVELS' variant='smallheading' color='#c79d5b' />
                              <Text title='All levels' textTransform='capitalize' fontSize='14px' padding='10px 0 0' />
                            </Box>
                          </Flex>
                        </Box>
                      </AccordionPanel>
                    </>
                  )}
                </AccordionItem>
              </Accordion>
              <Accordion allowToggle>
                <AccordionItem>
                  {({ isExpanded }) => (
                    <>
                      <AccordionButton padding='15px 10px'>
                        <Box className='accHeading' as="span" flex='1' textAlign='left' paddingRight="10px">
                          <Heading title='GLUTES & ABS' variant='extrasmall' />
                        </Box>
                        <Flex width={{ md: '60%' }}>
                          <Box display={{ sm: "none", md: "block" }} className='accHeading' as="span" flex='1' textAlign='left' paddingRight="10px">
                            <Heading title='INTENSITY' variant='smallheading' color='#c79d5b' />
                            <Flex paddingTop='10px'>
                              <i class="bi bi-egg-fill" style={{ fontSize: '14px' }}></i>
                              <i class="bi bi-egg-fill" style={{ fontSize: '14px' }}></i>
                              <i class="bi bi-egg" style={{ fontSize: '14px' }}></i>
                            </Flex>
                          </Box>
                          <Box display={{ sm: "none", md: "block" }} className='accHeading' as="span" flex='1' textAlign='left' paddingRight="10px">
                            <Heading title='‘Strength' variant='smallheading' color='#c79d5b' />
                            <Flex paddingTop='10px'>
                              <i class="bi bi-egg-fill" style={{ fontSize: '14px' }}></i>
                              <i class="bi bi-egg-fill" style={{ fontSize: '14px' }}></i>
                              <i class="bi bi-egg-fill" style={{ fontSize: '14px' }}></i>
                            </Flex>
                          </Box>
                          <Box display={{ sm: "none", md: "block" }} className='accHeading' as="span" flex='1' textAlign='left' paddingRight="10px">
                            <Heading title='DURATION' variant='smallheading' color='#c79d5b' />
                            <Text title='45 mins' textTransform='lowercase' fontSize='14px' padding='10px 0 0' />
                          </Box>
                          <Box display={{ sm: "none", md: "block" }} className='accHeading' as="span" flex='1' textAlign='left' paddingRight="10px">
                            <Heading title='LEVELS' variant='smallheading' color='#c79d5b' />
                            <Text title='All levels' textTransform='capitalize' fontSize='14px' padding='10px 0 0' />
                          </Box>
                          {isExpanded ? (
                            <MinusIcon fontSize={{ sm: "10px", md: "12px" }} />
                          ) : (
                            <AddIcon fontSize={{ sm: "10px", md: "12px" }} />
                          )}
                        </Flex>
                      </AccordionButton>

                      <AccordionPanel pb={4}>
                        <Box marginLeft={{ md: "40%" }}>
                          <Text fontSize={{ sm: '14px', md: '16px' }} title="This class will focus on the parts we want to show off. Yes, we will work on your glutes while strengthening your midline so you can feel confident with showing off your abs. It’s a slow burn you won’t want to miss." />
                          <Flex display={{ sm: "flex", md: "none" }} alignItems='center' justifyContent='space-between' paddingTop='20px' flexWrap='wrap'>
                            <Box className='accHeading'>
                              <Heading title='INTENSITY' variant='smallheading' color='#c79d5b' />
                              <Flex paddingTop='10px'>
                                <i class="bi bi-egg-fill" style={{ fontSize: '14px' }}></i>
                                <i class="bi bi-egg-fill" style={{ fontSize: '14px' }}></i>
                                <i class="bi bi-egg" style={{ fontSize: '14px' }}></i>
                              </Flex>
                            </Box>
                            <Box className='accHeading'>
                              <Heading title='‘Strength' variant='smallheading' color='#c79d5b' />
                              <Flex paddingTop='10px'>
                                <i class="bi bi-egg-fill" style={{ fontSize: '14px' }}></i>
                                <i class="bi bi-egg-fill" style={{ fontSize: '14px' }}></i>
                                <i class="bi bi-egg-fill" style={{ fontSize: '14px' }}></i>
                              </Flex>
                            </Box>
                            <Box className='accHeading'>
                              <Heading title='DURATION' variant='smallheading' color='#c79d5b' />
                              <Text title='45 mins' textTransform='lowercase' fontSize='14px' padding='10px 0 0' />
                            </Box>
                            <Box className='accHeading'>
                              <Heading title='LEVELS' variant='smallheading' color='#c79d5b' />
                              <Text title='All levels' textTransform='capitalize' fontSize='14px' padding='10px 0 0' />
                            </Box>
                          </Flex>
                        </Box>
                      </AccordionPanel>
                    </>
                  )}
                </AccordionItem>
              </Accordion>
              <Accordion allowToggle>
                <AccordionItem>
                  {({ isExpanded }) => (
                    <>
                      <AccordionButton padding='15px 10px'>
                        <Box className='accHeading' as="span" flex='1' textAlign='left' paddingRight="10px">
                          <Heading title='FULL BODY | ALL IN' variant='extrasmall' />
                        </Box>
                        <Flex width={{ md: '60%' }}>
                          <Box display={{ sm: "none", md: "block" }} className='accHeading' as="span" flex='1' textAlign='left' paddingRight="10px">
                            <Heading title='INTENSITY' variant='smallheading' color='#c79d5b' />
                            <Flex paddingTop='10px'>
                              <i class="bi bi-egg-fill" style={{ fontSize: '14px' }}></i>
                              <i class="bi bi-egg-fill" style={{ fontSize: '14px' }}></i>
                              <i class="bi bi-egg-fill" style={{ fontSize: '14px' }}></i>
                            </Flex>
                          </Box>
                          <Box display={{ sm: "none", md: "block" }} className='accHeading' as="span" flex='1' textAlign='left' paddingRight="10px">
                            <Heading title='‘Strength' variant='smallheading' color='#c79d5b' />
                            <Flex paddingTop='10px'>
                              <i class="bi bi-egg-fill" style={{ fontSize: '14px' }}></i>
                              <i class="bi bi-egg" style={{ fontSize: '14px' }}></i>
                              <i class="bi bi-egg" style={{ fontSize: '14px' }}></i>
                            </Flex>
                          </Box>
                          <Box display={{ sm: "none", md: "block" }} className='accHeading' as="span" flex='1' textAlign='left' paddingRight="10px">
                            <Heading title='DURATION' variant='smallheading' color='#c79d5b' />
                            <Text title='45 mins' textTransform='lowercase' fontSize='14px' padding='10px 0 0' />
                          </Box>
                          <Box display={{ sm: "none", md: "block" }} className='accHeading' as="span" flex='1' textAlign='left' paddingRight="10px">
                            <Heading title='LEVELS' variant='smallheading' color='#c79d5b' />
                            <Text title='All levels' textTransform='capitalize' fontSize='14px' padding='10px 0 0' />
                          </Box>
                          {isExpanded ? (
                            <MinusIcon fontSize={{ sm: "10px", md: "12px" }} />
                          ) : (
                            <AddIcon fontSize={{ sm: "10px", md: "12px" }} />
                          )}
                        </Flex>
                      </AccordionButton>

                      <AccordionPanel pb={4}>
                        <Box marginLeft={{ md: "40%" }}>
                          <Text fontSize={{ sm: '14px', md: '16px' }} title=" Let's empty the tank on the treadmill and floor! Get ready for the best full body workout ever!" />
                          <Flex display={{ sm: "flex", md: "none" }} alignItems='center' justifyContent='space-between' paddingTop='20px' flexWrap='wrap'>
                            <Box className='accHeading'>
                              <Heading title='INTENSITY' variant='smallheading' color='#c79d5b' />
                              <Flex paddingTop='10px'>
                                <i class="bi bi-egg-fill" style={{ fontSize: '14px' }}></i>
                                <i class="bi bi-egg-fill" style={{ fontSize: '14px' }}></i>
                                <i class="bi bi-egg-fill" style={{ fontSize: '14px' }}></i>
                              </Flex>
                            </Box>
                            <Box className='accHeading'>
                              <Heading title='‘Strength' variant='smallheading' color='#c79d5b' />
                              <Flex paddingTop='10px'>
                                <i class="bi bi-egg-fill" style={{ fontSize: '14px' }}></i>
                                <i class="bi bi-egg" style={{ fontSize: '14px' }}></i>
                                <i class="bi bi-egg" style={{ fontSize: '14px' }}></i>
                              </Flex>
                            </Box>
                            <Box className='accHeading'>
                              <Heading title='DURATION' variant='smallheading' color='#c79d5b' />
                              <Text title='45 mins' textTransform='lowercase' fontSize='14px' padding='10px 0 0' />
                            </Box>
                            <Box className='accHeading'>
                              <Heading title='LEVELS' variant='smallheading' color='#c79d5b' />
                              <Text title='All levels' textTransform='capitalize' fontSize='14px' padding='10px 0 0' />
                            </Box>
                          </Flex>
                        </Box>
                      </AccordionPanel>
                    </>
                  )}
                </AccordionItem>
              </Accordion>
              <Accordion allowToggle>
                <AccordionItem>
                  {({ isExpanded }) => (
                    <>
                      <AccordionButton padding='15px 10px'>
                        <Box className='accHeading' as="span" flex='1' textAlign='left' paddingRight="10px">
                          <Heading title='CHEST, ARMS & ABS' variant='extrasmall' />
                        </Box>
                        <Flex width={{ md: '60%' }}>
                          <Box display={{ sm: "none", md: "block" }} className='accHeading' as="span" flex='1' textAlign='left' paddingRight="10px">
                            <Heading title='INTENSITY' variant='smallheading' color='#c79d5b' />
                            <Flex paddingTop='10px'>
                              <i class="bi bi-egg-fill" style={{ fontSize: '14px' }}></i>
                              <i class="bi bi-egg-fill" style={{ fontSize: '14px' }}></i>
                              <i class="bi bi-egg" style={{ fontSize: '14px' }}></i>
                            </Flex>
                          </Box>
                          <Box display={{ sm: "none", md: "block" }} className='accHeading' as="span" flex='1' textAlign='left' paddingRight="10px">
                            <Heading title='‘Strength' variant='smallheading' color='#c79d5b' />
                            <Flex paddingTop='10px'>
                              <i class="bi bi-egg-fill" style={{ fontSize: '14px' }}></i>
                              <i class="bi bi-egg-fill" style={{ fontSize: '14px' }}></i>
                              <i class="bi bi-egg-fill" style={{ fontSize: '14px' }}></i>
                            </Flex>
                          </Box>
                          <Box display={{ sm: "none", md: "block" }} className='accHeading' as="span" flex='1' textAlign='left' paddingRight="10px">
                            <Heading title='DURATION' variant='smallheading' color='#c79d5b' />
                            <Text title='45 mins' textTransform='lowercase' fontSize='14px' padding='10px 0 0' />
                          </Box>
                          <Box display={{ sm: "none", md: "block" }} className='accHeading' as="span" flex='1' textAlign='left' paddingRight="10px">
                            <Heading title='LEVELS' variant='smallheading' color='#c79d5b' />
                            <Text title='All levels' textTransform='capitalize' fontSize='14px' padding='10px 0 0' />
                          </Box>
                          {isExpanded ? (
                            <MinusIcon fontSize={{ sm: "10px", md: "12px" }} />
                          ) : (
                            <AddIcon fontSize={{ sm: "10px", md: "12px" }} />
                          )}
                        </Flex>
                      </AccordionButton>

                      <AccordionPanel pb={4}>
                        <Box marginLeft={{ md: "40%" }}>
                          <Text fontSize={{ sm: '14px', md: '16px' }} title="We’re giving you the best upper body workout ever. The primary muscle groups of your upper body will have you standing taller, pushing harder and will give you the best abs around." />
                          <Flex display={{ sm: "flex", md: "none" }} alignItems='center' justifyContent='space-between' paddingTop='20px' flexWrap='wrap'>
                            <Box className='accHeading'>
                              <Heading title='INTENSITY' variant='smallheading' color='#c79d5b' />
                              <Flex paddingTop='10px'>
                                <i class="bi bi-egg-fill" style={{ fontSize: '14px' }}></i>
                                <i class="bi bi-egg-fill" style={{ fontSize: '14px' }}></i>
                                <i class="bi bi-egg" style={{ fontSize: '14px' }}></i>
                              </Flex>
                            </Box>
                            <Box className='accHeading'>
                              <Heading title='‘Strength' variant='smallheading' color='#c79d5b' />
                              <Flex paddingTop='10px'>
                                <i class="bi bi-egg-fill" style={{ fontSize: '14px' }}></i>
                                <i class="bi bi-egg-fill" style={{ fontSize: '14px' }}></i>
                                <i class="bi bi-egg-fill" style={{ fontSize: '14px' }}></i>
                              </Flex>
                            </Box>
                            <Box className='accHeading'>
                              <Heading title='DURATION' variant='smallheading' color='#c79d5b' />
                              <Text title='45 mins' textTransform='lowercase' fontSize='14px' padding='10px 0 0' />
                            </Box>
                            <Box className='accHeading'>
                              <Heading title='LEVELS' variant='smallheading' color='#c79d5b' />
                              <Text title='All levels' textTransform='capitalize' fontSize='14px' padding='10px 0 0' />
                            </Box>
                          </Flex>
                        </Box>
                      </AccordionPanel>
                    </>
                  )}
                </AccordionItem>
              </Accordion>
              <Accordion allowToggle>
                <AccordionItem>
                  {({ isExpanded }) => (
                    <>
                      <AccordionButton padding='15px 10px'>
                        <Box className='accHeading' as="span" flex='1' textAlign='left' paddingRight="10px">
                          <Heading title='FULL BODY | STRENGTH' variant='extrasmall' />
                        </Box>
                        <Flex width={{ md: '60%' }}>
                          <Box display={{ sm: "none", md: "block" }} className='accHeading' as="span" flex='1' textAlign='left' paddingRight="10px">
                            <Heading title='INTENSITY' variant='smallheading' color='#c79d5b' />
                            <Flex paddingTop='10px'>
                              <i class="bi bi-egg-fill" style={{ fontSize: '14px' }}></i>
                              <i class="bi bi-egg" style={{ fontSize: '14px' }}></i>
                              <i class="bi bi-egg" style={{ fontSize: '14px' }}></i>
                            </Flex>
                          </Box>
                          <Box display={{ sm: "none", md: "block" }} className='accHeading' as="span" flex='1' textAlign='left' paddingRight="10px">
                            <Heading title='‘Strength' variant='smallheading' color='#c79d5b' />
                            <Flex paddingTop='10px'>
                              <i class="bi bi-egg-fill" style={{ fontSize: '14px' }}></i>
                              <i class="bi bi-egg-fill" style={{ fontSize: '14px' }}></i>
                              <i class="bi bi-egg-fill" style={{ fontSize: '14px' }}></i>
                            </Flex>
                          </Box>
                          <Box display={{ sm: "none", md: "block" }} className='accHeading' as="span" flex='1' textAlign='left' paddingRight="10px">
                            <Heading title='DURATION' variant='smallheading' color='#c79d5b' />
                            <Text title='45 mins' textTransform='lowercase' fontSize='14px' padding='10px 0 0' />
                          </Box>
                          <Box display={{ sm: "none", md: "block" }} className='accHeading' as="span" flex='1' textAlign='left' paddingRight="10px">
                            <Heading title='LEVELS' variant='smallheading' color='#c79d5b' />
                            <Text title='All levels' textTransform='capitalize' fontSize='14px' padding='10px 0 0' />
                          </Box>
                          {isExpanded ? (
                            <MinusIcon fontSize={{ sm: "10px", md: "12px" }} />
                          ) : (
                            <AddIcon fontSize={{ sm: "10px", md: "12px" }} />
                          )}
                        </Flex>
                      </AccordionButton>

                      <AccordionPanel pb={4}>
                        <Box marginLeft={{ md: "40%" }}>
                          <Text fontSize={{ sm: '14px', md: '16px' }} title="We’re incorporating compound movements in this class so you can get stronger. Get out those weigher weights, focus on good form and be prepared to strength your body. Nothing can stop you!" />
                          <Flex display={{ sm: "flex", md: "none" }} alignItems='center' justifyContent='space-between' paddingTop='20px' flexWrap='wrap'>
                            <Box className='accHeading'>
                              <Heading title='INTENSITY' variant='smallheading' color='#c79d5b' />
                              <Flex paddingTop='10px'>
                                <i class="bi bi-egg-fill" style={{ fontSize: '14px' }}></i>
                                <i class="bi bi-egg" style={{ fontSize: '14px' }}></i>
                                <i class="bi bi-egg" style={{ fontSize: '14px' }}></i>
                              </Flex>
                            </Box>
                            <Box className='accHeading'>
                              <Heading title='‘Strength' variant='smallheading' color='#c79d5b' />
                              <Flex paddingTop='10px'>
                                <i class="bi bi-egg-fill" style={{ fontSize: '14px' }}></i>
                                <i class="bi bi-egg-fill" style={{ fontSize: '14px' }}></i>
                                <i class="bi bi-egg-fill" style={{ fontSize: '14px' }}></i>
                              </Flex>
                            </Box>
                            <Box className='accHeading'>
                              <Heading title='DURATION' variant='smallheading' color='#c79d5b' />
                              <Text title='45 mins' textTransform='lowercase' fontSize='14px' padding='10px 0 0' />
                            </Box>
                            <Box className='accHeading'>
                              <Heading title='LEVELS' variant='smallheading' color='#c79d5b' />
                              <Text title='All levels' textTransform='capitalize' fontSize='14px' padding='10px 0 0' />
                            </Box>
                          </Flex>
                        </Box>
                      </AccordionPanel>
                    </>
                  )}
                </AccordionItem>
              </Accordion>
              <Accordion allowToggle>
                <AccordionItem>
                  {({ isExpanded }) => (
                    <>
                      <AccordionButton padding='15px 10px'>
                        <Box className='accHeading' as="span" flex='1' textAlign='left' paddingRight="10px">
                          <Heading title='FULL BODY | ALL IN - 60MIN' variant='extrasmall' />
                        </Box>
                        <Flex width={{ md: '60%' }}>
                          <Box display={{ sm: "none", md: "block" }} className='accHeading' as="span" flex='1' textAlign='left' paddingRight="10px">
                            <Heading title='INTENSITY' variant='smallheading' color='#c79d5b' />
                            <Flex paddingTop='10px'>
                              <i class="bi bi-egg-fill" style={{ fontSize: '14px' }}></i>
                              <i class="bi bi-egg-fill" style={{ fontSize: '14px' }}></i>
                              <i class="bi bi-egg-fill" style={{ fontSize: '14px' }}></i>
                            </Flex>
                          </Box>
                          <Box display={{ sm: "none", md: "block" }} className='accHeading' as="span" flex='1' textAlign='left' paddingRight="10px">
                            <Heading title='‘Strength' variant='smallheading' color='#c79d5b' />
                            <Flex paddingTop='10px'>
                              <i class="bi bi-egg-fill" style={{ fontSize: '14px' }}></i>
                              <i class="bi bi-egg-fill" style={{ fontSize: '14px' }}></i>
                              <i class="bi bi-egg" style={{ fontSize: '14px' }}></i>
                            </Flex>
                          </Box>
                          <Box display={{ sm: "none", md: "block" }} className='accHeading' as="span" flex='1' textAlign='left' paddingRight="10px">
                            <Heading title='DURATION' variant='smallheading' color='#c79d5b' />
                            <Text title='45 mins' textTransform='lowercase' fontSize='14px' padding='10px 0 0' />
                          </Box>
                          <Box display={{ sm: "none", md: "block" }} className='accHeading' as="span" flex='1' textAlign='left' paddingRight="10px">
                            <Heading title='LEVELS' variant='smallheading' color='#c79d5b' />
                            <Text title='All levels' textTransform='capitalize' fontSize='14px' padding='10px 0 0' />
                          </Box>
                          {isExpanded ? (
                            <MinusIcon fontSize={{ sm: "10px", md: "12px" }} />
                          ) : (
                            <AddIcon fontSize={{ sm: "10px", md: "12px" }} />
                          )}
                        </Flex>
                      </AccordionButton>

                      <AccordionPanel pb={4}>
                        <Box marginLeft={{ md: "40%" }}>
                          <Text fontSize={{ sm: '14px', md: '16px' }} title="Let's empty the tank on the treadmill and floor! Get ready for the best full body workout ever!" />
                          <Flex display={{ sm: "flex", md: "none" }} alignItems='center' justifyContent='space-between' paddingTop='20px' flexWrap='wrap'>
                            <Box className='accHeading'>
                              <Heading title='INTENSITY' variant='smallheading' color='#c79d5b' />
                              <Flex paddingTop='10px'>
                                <i class="bi bi-egg-fill" style={{ fontSize: '14px' }}></i>
                                <i class="bi bi-egg-fill" style={{ fontSize: '14px' }}></i>
                                <i class="bi bi-egg-fill" style={{ fontSize: '14px' }}></i>
                              </Flex>
                            </Box>
                            <Box className='accHeading'>
                              <Heading title='‘Strength' variant='smallheading' color='#c79d5b' />
                              <Flex paddingTop='10px'>
                                <i class="bi bi-egg-fill" style={{ fontSize: '14px' }}></i>
                                <i class="bi bi-egg-fill" style={{ fontSize: '14px' }}></i>
                                <i class="bi bi-egg" style={{ fontSize: '14px' }}></i>
                              </Flex>
                            </Box>
                            <Box className='accHeading'>
                              <Heading title='DURATION' variant='smallheading' color='#c79d5b' />
                              <Text title='45 mins' textTransform='lowercase' fontSize='14px' padding='10px 0 0' />
                            </Box>
                            <Box className='accHeading'>
                              <Heading title='LEVELS' variant='smallheading' color='#c79d5b' />
                              <Text title='All levels' textTransform='capitalize' fontSize='14px' padding='10px 0 0' />
                            </Box>
                          </Flex>
                        </Box>
                      </AccordionPanel>
                    </>
                  )}
                </AccordionItem>
              </Accordion>
              <Accordion allowToggle>
                <AccordionItem>
                  {({ isExpanded }) => (
                    <>
                      <AccordionButton padding='15px 10px'>
                        <Box className='accHeading' as="span" flex='1' textAlign='left' paddingRight="10px">
                          <Heading title='FULL BODY | LOWER BODY FOCUS' variant='extrasmall' />
                        </Box>
                        <Flex width={{ md: '60%' }}>
                          <Box display={{ sm: "none", md: "block" }} className='accHeading' as="span" flex='1' textAlign='left' paddingRight="10px">
                            <Heading title='INTENSITY' variant='smallheading' color='#c79d5b' />
                            <Flex paddingTop='10px'>
                              <i class="bi bi-egg-fill" style={{ fontSize: '14px' }}></i>
                              <i class="bi bi-egg-fill" style={{ fontSize: '14px' }}></i>
                              <i class="bi bi-egg" style={{ fontSize: '14px' }}></i>
                            </Flex>
                          </Box>
                          <Box display={{ sm: "none", md: "block" }} className='accHeading' as="span" flex='1' textAlign='left' paddingRight="10px">
                            <Heading title='‘Strength' variant='smallheading' color='#c79d5b' />
                            <Flex paddingTop='10px'>
                              <i class="bi bi-egg-fill" style={{ fontSize: '14px' }}></i>
                              <i class="bi bi-egg-fill" style={{ fontSize: '14px' }}></i>
                              <i class="bi bi-egg-fill" style={{ fontSize: '14px' }}></i>
                            </Flex>
                          </Box>
                          <Box display={{ sm: "none", md: "block" }} className='accHeading' as="span" flex='1' textAlign='left' paddingRight="10px">
                            <Heading title='DURATION' variant='smallheading' color='#c79d5b' />
                            <Text title='45 mins' textTransform='lowercase' fontSize='14px' padding='10px 0 0' />
                          </Box>
                          <Box display={{ sm: "none", md: "block" }} className='accHeading' as="span" flex='1' textAlign='left' paddingRight="10px">
                            <Heading title='LEVELS' variant='smallheading' color='#c79d5b' />
                            <Text title='All levels' textTransform='capitalize' fontSize='14px' padding='10px 0 0' />
                          </Box>
                          {isExpanded ? (
                            <MinusIcon fontSize={{ sm: "10px", md: "12px" }} />
                          ) : (
                            <AddIcon fontSize={{ sm: "10px", md: "12px" }} />
                          )}
                        </Flex>
                      </AccordionButton>

                      <AccordionPanel pb={4}>
                        <Box marginLeft={{ md: "40%" }}>
                          <Text fontSize={{ sm: '14px', md: '16px' }} title="We’re going work your lower body and also add in some upper body push and pull movement to give you a workout that will leave you feeling the best ever!" />
                          <Flex display={{ sm: "flex", md: "none" }} alignItems='center' justifyContent='space-between' paddingTop='20px' flexWrap='wrap'>
                            <Box className='accHeading'>
                              <Heading title='INTENSITY' variant='smallheading' color='#c79d5b' />
                              <Flex paddingTop='10px'>
                                <i class="bi bi-egg-fill" style={{ fontSize: '14px' }}></i>
                                <i class="bi bi-egg-fill" style={{ fontSize: '14px' }}></i>
                                <i class="bi bi-egg" style={{ fontSize: '14px' }}></i>
                              </Flex>
                            </Box>
                            <Box className='accHeading'>
                              <Heading title='‘Strength' variant='smallheading' color='#c79d5b' />
                              <Flex paddingTop='10px'>
                                <i class="bi bi-egg-fill" style={{ fontSize: '14px' }}></i>
                                <i class="bi bi-egg-fill" style={{ fontSize: '14px' }}></i>
                                <i class="bi bi-egg-fill" style={{ fontSize: '14px' }}></i>
                              </Flex>
                            </Box>
                            <Box className='accHeading'>
                              <Heading title='DURATION' variant='smallheading' color='#c79d5b' />
                              <Text title='45 mins' textTransform='lowercase' fontSize='14px' padding='10px 0 0' />
                            </Box>
                            <Box className='accHeading'>
                              <Heading title='LEVELS' variant='smallheading' color='#c79d5b' />
                              <Text title='All levels' textTransform='capitalize' fontSize='14px' padding='10px 0 0' />
                            </Box>
                          </Flex>
                        </Box>
                      </AccordionPanel>
                    </>
                  )}
                </AccordionItem>
              </Accordion>
              <Accordion allowToggle>
                <AccordionItem>
                  {({ isExpanded }) => (
                    <>
                      <AccordionButton padding='15px 10px'>
                        <Box className='accHeading' as="span" flex='1' textAlign='left' paddingRight="10px">
                          <Heading title='FULL BODY | LOWER BODY FOCUS - 60MIN' variant='extrasmall' />
                        </Box>
                        <Flex width={{ md: '60%' }}>
                          <Box display={{ sm: "none", md: "block" }} className='accHeading' as="span" flex='1' textAlign='left' paddingRight="10px">
                            <Heading title='INTENSITY' variant='smallheading' color='#c79d5b' />
                            <Flex paddingTop='10px'>
                              <i class="bi bi-egg-fill" style={{ fontSize: '14px' }}></i>
                              <i class="bi bi-egg-fill" style={{ fontSize: '14px' }}></i>
                              <i class="bi bi-egg" style={{ fontSize: '14px' }}></i>
                            </Flex>
                          </Box>
                          <Box display={{ sm: "none", md: "block" }} className='accHeading' as="span" flex='1' textAlign='left' paddingRight="10px">
                            <Heading title='‘Strength' variant='smallheading' color='#c79d5b' />
                             <Flex paddingTop='10px'>
                              <i class="bi bi-egg-fill" style={{ fontSize: '14px' }}></i>
                              <i class="bi bi-egg-fill" style={{ fontSize: '14px' }}></i>
                              <i class="bi bi-egg-fill" style={{ fontSize: '14px' }}></i>
                            </Flex>
                          </Box>
                          <Box display={{ sm: "none", md: "block" }} className='accHeading' as="span" flex='1' textAlign='left' paddingRight="10px">
                            <Heading title='DURATION' variant='smallheading' color='#c79d5b' />
                            <Text title='45 mins' textTransform='lowercase' fontSize='14px' padding='10px 0 0' />
                          </Box>
                          <Box display={{ sm: "none", md: "block" }} className='accHeading' as="span" flex='1' textAlign='left' paddingRight="10px">
                            <Heading title='LEVELS' variant='smallheading' color='#c79d5b' />
                            <Text title='All levels' textTransform='capitalize' fontSize='14px' padding='10px 0 0' />
                          </Box>
                          {isExpanded ? (
                            <MinusIcon fontSize={{ sm: "10px", md: "12px" }} />
                          ) : (
                            <AddIcon fontSize={{ sm: "10px", md: "12px" }} />
                          )}
                        </Flex>
                      </AccordionButton>

                      <AccordionPanel pb={4}>
                        <Box marginLeft={{ md: "40%" }}>
                          <Text fontSize={{ sm: '14px', md: '16px' }} title="We’re going work your lower body and also add in some upper body push and pull movement to give you a workout that will leave you feeling the best ever!" />
                          <Flex display={{ sm: "flex", md: "none" }} alignItems='center' justifyContent='space-between' paddingTop='20px' flexWrap='wrap'>
                            <Box className='accHeading'>
                              <Heading title='INTENSITY' variant='smallheading' color='#c79d5b' />
                              <Flex paddingTop='10px'>
                                <i class="bi bi-egg-fill" style={{ fontSize: '14px' }}></i>
                                <i class="bi bi-egg-fill" style={{ fontSize: '14px' }}></i>
                                <i class="bi bi-egg" style={{ fontSize: '14px' }}></i>
                              </Flex>
                            </Box>
                            <Box className='accHeading'>
                              <Heading title='‘Strength' variant='smallheading' color='#c79d5b' />
                              <Flex paddingTop='10px'>
                                <i class="bi bi-egg-fill" style={{ fontSize: '14px' }}></i>
                                <i class="bi bi-egg-fill" style={{ fontSize: '14px' }}></i>
                                <i class="bi bi-egg-fill" style={{ fontSize: '14px' }}></i>
                              </Flex>
                            </Box>
                            <Box className='accHeading'>
                              <Heading title='DURATION' variant='smallheading' color='#c79d5b' />
                              <Text title='45 mins' textTransform='lowercase' fontSize='14px' padding='10px 0 0' />
                            </Box>
                            <Box className='accHeading'>
                              <Heading title='LEVELS' variant='smallheading' color='#c79d5b' />
                              <Text title='All levels' textTransform='capitalize' fontSize='14px' padding='10px 0 0' />
                            </Box>
                          </Flex>
                        </Box>
                      </AccordionPanel>
                    </>
                  )}
                </AccordionItem>
              </Accordion>
            </Box>
          </Box>
        </Container>
      </Flex>

      <Flex flexDirection='column' padding={{ sm: '0 0 70px', md: '50px 0px 100px'}} position="relative">
        <Container maxW='container.xl'>
          <Testimonial headingWidth={{ sm: '97%', md: '90%', lg: '87%', xl: '69%' }} isicon={false} isname={false} title='“THE COACHES ARE BOTH PROFESSIONAL AND WELL-TRAINED. I WOULD ABSOLUTELY RECOMMEND PERPETUA TO ANYONE, FROM A BEGINNER TO A EXPERIENCED GYM USER”' />
        </Container>
      </Flex>

      <Box padding={{sm:"0 0 50px", md:"0 0 70px"}}>
        <Container maxW='container.xl'>
          <Heading title='Tread & Shred Instructors' variant='large' textAlign='center' />
          <TabItem items={items} />
          <Center>
              <NavLink to='/team'>
              <Button title='see the full team' color='#000' />
              </NavLink>
          </Center>
        </Container>
      </Box>

      {/* <VideoSection heading='Tread & Shred' headingSub='at Perpetua' video1='https://youtube.com/embed/hz9o7KrqVSE' video2='https://youtube.com/embed/b8PWlDPQo0U' smallHeading='Tread & Shred at Perpetua Fitness' smallHeading2='Welcome to Tread & Shred' midHeading='' midHeading2='' /> */}

      <Box backgroundColor='black' padding={{ sm: '60px 0px', md: '80px 0px' }} borderTop='1px solid #232323'>
        <Container maxW='container.xl'>
          
            <Box>
              <Heading 
              variant="large" 
              title='Tread & Shred' 
              lineHeight='1'
              color='white'
              textAlign='center'
              />
              <Heading 
              variant="large" 
              title='at Perpetua' 
              lineHeight='1'
              color='white'
              textAlign='center'
              />
            </Box>
            <SimpleGrid
            columns={{sm:"1",md:"2",xl:"4"}}
            justifyContent='center'
            gap='20px'
            paddingTop='30px'
            >
              <Style.VideoWrapper width='100% !important'>
                <LazyLoad offset={300}>
                  <Box as='iframe' src='https://youtube.com/embed/PwZiFngkSFI?si=yL_ItKASuYwQNmk9' width={{sm:'100%', md:'100%'}}  height='550px'/>
                </LazyLoad>
                <Style.TextWrapper>
                  <Heading 
                  variant="smallheading" 
                  title='Find out more' 
                  lineHeight='1'
                  color='white'
                  marginBottom='5px'
                  />
                </Style.TextWrapper>
              </Style.VideoWrapper>
              <Style.VideoWrapper width='100% !important'>
                <LazyLoad offset={300}>
                  <Box as='iframe' src='https://youtube.com/embed/3mwRJOrRWzs?si=BrThLHFKeb2zvt5i' width={{sm:'100%', md:'100%'}}  height='550px'/>
                </LazyLoad>
                <Style.TextWrapper>
                  <Heading 
                  variant="smallheading" 
                  title='What our clients say' 
                  lineHeight='1'
                  color='white'
                  marginBottom='5px'
                  />

                </Style.TextWrapper>
              </Style.VideoWrapper>
              
              <Style.VideoWrapper width='100% !important'>
                <LazyLoad offset={300}>
                  <Box as='iframe' src='https://youtube.com/embed/hz9o7KrqVSE' width={{sm:'100%', md:'100%'}}  height='550px'/>
                </LazyLoad>
                <Style.TextWrapper>
                  <Heading 
                  variant="smallheading" 
                  title='Tread & Shred at Perpetua Fitness' 
                  lineHeight='1'
                  color='white'
                  marginBottom='5px'
                  />
                </Style.TextWrapper>
              </Style.VideoWrapper>
              <Style.VideoWrapper width='100% !important'>
                <LazyLoad offset={300}>
                  <Box as='iframe' src='https://youtube.com/embed/b8PWlDPQo0U' width={{sm:'100%', md:'100%'}}  height='550px'/>
                </LazyLoad>
                <Style.TextWrapper>
                  <Heading 
                  variant="smallheading" 
                  title='Welcome to Tread & Shred' 
                  lineHeight='1'
                  color='white'
                  marginBottom='5px'
                  />
                  
                </Style.TextWrapper>
              </Style.VideoWrapper>
            </SimpleGrid>
          
        </Container>
      </Box>

      <Box
        backgroundImage='url(./images/threadAndshreadimg3.webp)' 
        backgroundColor='black'
        backgroundPosition="center"
        backgroundRepeat="no-repeat"
        height={{ sm: '150vh', md: '767px', lg: "850px", xl: "767px" }}
        backgroundSize={"cover"}
        width='100%'
      >
        <Container maxW='container.2xl' p={{ sm: '0', lg: "0 0 0 130px" }} height='100%' width='100%'
        backgroundColor='rgba(0,0,0,0.5)'
        >
          <SimpleGrid
            columns={{ sm: '1', lg: '2' }}
            columnGap={10}
            rowGap={2}
            height='100%'
            width='100%'
          >
            <GridItem
              display='flex'
              alignItems='end'
              paddingBottom="60px"
              className='wow animate fadeInUp'
              paddingLeft={{ sm: "20px", lg: "0" }}
            >
              <Box>
                <Heading title='Benefits of Tread & Shred Classes' color='#fff' lineHeight="1" padding="0px 0px 5px" variant="extralarge" width={{ lg: "75%" }} />
                <Box>
                  <NavLink to='/timetable'><Button margin='20px 0 0' title="Book a class" /> </NavLink>
                </Box>
              </Box>
            </GridItem>
            <GridItem
              borderLeft={{ lg: '1px solid rgba(255, 255, 255, .4)' }}
              height='100%'
              width='100%'
              borderTop={{ sm: '1px solid rgba(255, 255, 255, .4)', lg: "0" }}
            >
              <SimpleGrid
                columns={{ sm: '2', md: '2' }}
                height='100%'
                width='100%'
              >
                <GridItem
                  padding={{ sm: "20px", lg: "40px" }}
                  borderBottom='1px solid rgba(255, 255, 255, .4)'
                >
                  <Image src='./images/threadAndShread1.png' />
                  <Heading title='IMPROVE PERFORMANCE' variant='extrasmall' color='#fff' padding='10px 0' />
                  <Text title='Experience full-body transformation. Build muscle, increase strength and endurance, and improve movement, mobility and coordination.' color='#fff' fontSize={{ sm: "12px", xl: "14px" }} />
                </GridItem>
                <GridItem
                  padding={{ sm: "20px", lg: "40px" }}
                  borderBottom='1px solid rgba(255, 255, 255, .4)'
                  borderLeft='1px solid rgba(255, 255, 255, .4)'
                >
                  <Image src='./images/threadAndShread4.png' />
                  <Heading title='BOOST CARDIOVASCULAR HEALTH' variant='extrasmall' color='#fff' padding='10px 0' />
                  <Text title='Lower your risk of cardiovascular disease by improving your anaerobic threshold and increasing your peak oxygen consumption (VO2 max).' color='#fff' fontSize={{ sm: "12px", xl: "14px" }} />
                </GridItem>
                <GridItem
                  padding={{ sm: "20px", lg: "40px" }}
                  borderBottom='1px solid rgba(255, 255, 255, .4)'
                >
                  <Image src='./images/threadAndShread2.png' />
                  <Heading title='IMPROVE HEALTH OUTCOMES' variant='extrasmall' color='#fff' padding='10px 0' />
                  <Text title='Improve your metabolic rate, insulin sensitivity, glycemic control and cognitive function, and decrease your risk of metabolic syndrome, osteoarthritis, and type 2 diabetes.' color='#fff' fontSize={{ sm: "12px", xl: "14px" }} />
                </GridItem>
                <GridItem
                  padding={{ sm: "20px", lg: "40px" }}
                  borderBottom='1px solid rgba(255, 255, 255, .4)'
                  borderLeft='1px solid rgba(255, 255, 255, .4)'
                >
                  <Image src='./images/threadAndShread3.png' />
                  <Heading title='RELIEVE STRESS' variant='extrasmall' color='#fff' padding='10px 0' />
                  <Text title='Improve your mental health and help reduce symptoms of stress and anxiety through a well-balanced exercise regimen.' color='#fff' fontSize={{ sm: "12px", xl: "14px" }} />
                </GridItem>
              </SimpleGrid>
            </GridItem>

          </SimpleGrid>
        </Container>
      </Box>

      <Box
        padding={{ sm: "50px 0", md: "80px 0 0" }}
      >
        <Container maxW='container.xl'>
          <Heading title='Tread & Shred Studios'
            variant='medium'
            padding='0 0 30px'
          />
          <SimpleGrid
            columns={{ sm: '1', md: '2' }}
            columnGap={10}
            rowGap={2}
          >
            <GridItem>
              <Image src='./images/windmill-lane-tss.webp' alt='Windmill Lane studio' />
              <Heading title='Windmill Lane'
                variant='smallmid'
                padding='20px 0 10px'
              />
              <Text title="Get ready to experience the best group fitness class in Ireland in our state of the art
              Windmill Lane studio. Our studio has Technogym treadmills, Torpedo's, dumbbells, slam
              balls and benches.
              " />
              <Box paddingTop='20px'>
                <NavLink to='/timetable'>
                  <Button title='Book A Class' className="wow fadeInUp" color='black' />
                </NavLink>
              </Box>
            </GridItem>
            <GridItem paddingTop={{ sm: "30px", md: "0" }}>
              <Image src='./images/lennox-street-tss.webp' alt='Lennox Street studio' />
              <Heading title='Lennox Street'
                variant='smallmid'
                padding='20px 0 10px'
              />
              <Text title=" Get ready to experience the best group fitness class in Ireland in our state of the art
                Lennox Street studio. Our studio has Technogym treadmills, Torpedo's, dumbbells, slam
                balls and benches." />
              <Box paddingTop='20px'>
                <NavLink to='/timetable'>
                  <Button title='Book A Class' className="wow fadeInUp" color='black' />
                </NavLink>
              </Box>
            </GridItem>
          </SimpleGrid>
        </Container>
      </Box>

      <Flex
        flexDirection='column'
        padding={{ sm: '20px 0px 50px', md: '100px 0px 100px' }}
        textAlign='center'
        justifyContent='center'
        justifyItems='center'
      >
        <Container maxW='container.xl'>
          <Box>
            <Heading
              as="h2"
              title="ARE YOU READY?"
              variant='extralarge'
              textAlign="center"
              lineHeight="1"
              padding={{ base: '0px 0px 15px', sm: '0px 0px 30px' }}
            >
            </Heading>
            <Text
              title="Try 3 classes for €33. All fitness levels welcome "
              as='p'
            >
            </Text>
            <Link href='/pricing' _hover={{ textDecoration: 'none' }}><Button title='Get Started' className="wow fadeInUp" color='black' margin='40px 0px 0px ' /> </Link>
            <Box >
              <Heading
                as="h5"
                title="Download our app "
                variant='extrasmall'
                textAlign="center"
                lineHeight="1"
                padding={{ base: '15px 0px 0px', sm: '30px 0px 0px' }}
                display='inline-block'
              >

              </Heading>

              <Link href="https://play.google.com/store/apps/details?id=com.fitnessmobileapps.perpetuafitness" target='_blank' >
                <Heading
                  as="h5"
                  title="Here"
                  variant='extrasmall'
                  textAlign="center"
                  lineHeight="1"
                  padding={{ base: '15px 0px 0px', sm: '30px 0px 0px' }}
                  display='inline-block'
                  margin='0px 0px 0px 5px'
                  color='Red'
                  textDecoration='underline'
                ></Heading>
              </Link>
            </Box>
          </Box>
        </Container>
      </Flex>

      <Box padding={{ sm: '35px 0px 30px', md: '80px 0px' }} background={"#eeeef1"}>
        <Container maxW='container.xl' >
          <Flex margin="auto" textAlign={'center'} width={{ sm: '100%', md: '50%' }} flexDirection="column" paddingBottom="30px">
            <Heading
              lineHeight={"1"}
              variant="large"
              title="FREQUENTLY ASKED QUESTIONS"
              marginBottom='30px'
            />
            <Box mb="40px">
              <Text
                title="Questions about the classes or next steps? We got you. If you need more information, please feel free to contact us hello@perpetua.ie"
              />
            </Box>
          </Flex>

          <Flex flexDirection={{ sm: "column", md: "row" }}>

            <Box width={{ sm: '100%', md: '33.33%' }} paddingBottom={{ sm: "30px", md: 0 }}>
              <Heading
                lineHeight={"1"}
                variant="smallmid"
                title="BEFORE CLASS"
                marginBottom='20px'
                padding="0 15px"
              />
              <Accordian data={accoData} />
            </Box>
            <Box width={{ sm: '100%', md: '33.33%' }} paddingBottom={{ sm: "30px", md: 0 }}>
              <Heading
                lineHeight={"1"}
                variant="smallmid"
                title="DURING CLASS"
                marginBottom='20px'
                padding="0 15px"
              />
              <Accordian data={accoData2} />
            </Box>
            <Box width={{ sm: '100%', md: '33.33%' }} paddingBottom={{ sm: "30px", md: 0 }}>
              <Heading
                lineHeight={"1"}
                variant="smallmid"
                title="AFTER CLASS"
                marginBottom='20px'
                padding="0 15px"
              />
              <Accordian data={accoData3} />
            </Box>


          </Flex>





        </Container>
      </Box>
      <BlogSection catSlug='tread-shred' />
      <AppDownload
        bg="url('/images/App-section-bg.webp')"
        heading='Your Daily Dose of Online Workouts Is Live'
        para='The Best Online Training Platform'
        imageUri='/images/iphone.webp'
        appstore='/images/app-store.png'
        googleplay='/images/google-play.png'
        className="_appdownload_section"
        googleplaylink="https://play.google.com/store/apps/details?id=com.fitnessmobileapps.perpetuafitness"
        appstorelink="https://apps.apple.com/ie/app/perpetua-fitness/id1444090131"
        isAppButton={false}
        link="/online-gym-programming"
        alt="iphone - fitness classes dublin"
      />


    </>
  );
}

export default SweatPage;
