import { Flex,Box} from "@chakra-ui/react";
import styled from "@emotion/styled/macro";

export const Wrapper = styled(Flex)(props => ({
    alignItems:'center',
    flexDirection:'row',
    gap:30,
    "@media screen and (max-width:767px)": {
        flexDirection:'column',
    },

}))

export const LeftBox = styled(Box)(props => ({
    width:'30%',
    "@media screen and (max-width:991px)": {
        width:'30%',
    },
    "@media screen and (max-width:767px)": {
        width:'100%',
    },
}))
export const RightBox = styled(Flex)(props => ({
    width:'65%',
    alignItems:'center',
    flexDirection:'row',
    gap:20,
    "@media screen and (max-width:991px)": {
        width:'63%',
    },
    "@media screen and (max-width:767px)": {
        width:'100%',
    },
    "@media screen and (max-width:480px)": {
        flexDirection:'column',
    },
}))
export const VideoWrapper = styled(Box)(props => ({
    width:'49%',
    "@media screen and (max-width:480px)": {
        width:'100%',
        marginBottom:'30px'
    },
}))

export const TextWrapper = styled(Box)(props => ({
    width:'100%',
    textAlign:'center',
    paddingTop:'30px'
}))

// scroll add
 
export const VideoWrapperNatal = styled(Box)(props => ({
    justifyContent:'center',
    gap:'20px',
    paddingTop:'30px',
    display:"grid",
    gridTemplateColumns: "repeat(4, 1fr)",
    "@media screen and (max-width:1024px)": {
        gridTemplateColumns: "repeat(3, 1fr)",
    },
    "@media screen and (max-width:767px)": {
        display:"flex",
        overflow:"scroll",
        flexWrap: "nowrap",
        justifyContent: "flex-start",
    },
}))